import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle, } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Tooltips from '../bootstrap/Tooltips';
import Select from '../bootstrap/forms/Select';
import Collapse from '../bootstrap/Collapse';
import Modal, { ModalBody } from '../bootstrap/Modal';
import Accordion, { AccordionItem } from '../bootstrap/Accordion';
import Label from '../bootstrap/forms/Label';

const employeesProvidentFund = () => {

  const employeesProvidentFundForm = useFormik({
    initialValues: {
      epfNumber: '',
      deductionCycle: 'Monthly',
      employeeContributionRate: '12 % of actual PF Wage',
      employerContributionRate: 'Restrict contribution to ₹15000 of PF Wage',
      checkOne: true,
      checkTwo: false,
      checkThree: true,
    },

    validate: (values) => {
      const errors: {
        epfNumber?: string;
        deductionCycle?: string;
        employeeContributionRate?: string;
        employerContributionRate?: string;

      } = {};

      if (!values.epfNumber) {
        errors.epfNumber = 'Required';
      }
      if (!values.deductionCycle) {
        errors.deductionCycle = 'Required';
      }
      if (!values.employeeContributionRate) {
        errors.employeeContributionRate = 'Required';
      }
      if (!values.employerContributionRate) {
        errors.employerContributionRate = 'Required';
      }
      return errors;
    },

    onSubmit: () => {
      setIsLoading(true);
      setTimeout(handleSave, 2000);
    },

  });

  const flexChecks = useFormik({
    initialValues: {
      defaultCheck: false,
      checkedCheck: true,
    },
    onSubmit: () => { },
  });
  const flexRadios = useFormik({
    initialValues: {
      flexRadioDefault: 'first',
      flexRadioDisabled: 'third',
    },
    onSubmit: () => { },
  });

  const navigate = useNavigate();
  const [isSplitUpOpen, setIsSplitUpOpen] = useState(false);
  const [previewEpf, setPreviewEpf] = useState(false);

  const [lastSave, setLastSave] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSave = () => {
    setLastSave(dayjs());
    setIsLoading(false);
    showNotification(
      <span className='d-flex align-items-center'>
        <Icon icon='Info' size='lg' className='me-1' />
        <span>Updated Successfully</span>
      </span>,
      "The user's account details have been successfully updated.",
    );
  };

  //CheckBox
  const [checkOn, setCheckOn] = useState(false);
  function radioCheck() {
    setCheckOn(!checkOn)
  }
  const [editCtc, setEditCtc] = useState(false);
  function edit() {
    setEditCtc(!editCtc)
  }
  const [doChecked, setDoChecked] = useState(false);
  function doCheck() {
    setDoChecked(!doChecked)
  }
  const [checked, setChecked] = useState(false);
  function Check() {
    setChecked(!checked)
  }
  const [rate, setRate] = useState(false);
  function setRates() {
    setRate(!rate)
  }
  const [applied, setApplied] = useState(false);
  function setApply() {
    setApplied(!applied)
  }
  const [pay, setPay] = useState(false);
  function paid() {
    setPay(!pay)
  }
  const [employee, setEmployee] = useState(false);
  function onlyEmployee() {
    setEmployee(!employee)
  }

  return (
    <PageWrapper>
      <Page>
        <Card>
          <CardHeader>
            <CardLabel className="col-lg-5 ms-4" >
              <CardTitle tag='div' className='h5'>Employees' Provident Fund</CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody>
            <div className='row g-3'>
              <div className='col-md-8'>
                {/* <Card shadow='sm'> */}
                <CardBody>
                  <div className='row'>
                    <div className='col-6'>
                      <FormGroup id='epfNumber' label='EPF Number'>
                        <Input
                          type='number'
                          placeholder='00-00-000000-000-0000'
                          className='align-items-center'
                          autoComplete='additional-name'
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.epfNumber}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.epfNumber}
                          invalidFeedback={employeesProvidentFundForm.errors.epfNumber}
                          validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div>
                    <div className='col-6'>
                      <Label>Deduction Cycle <Tooltips title='Provident Fund(PF) contributions for each month should be deposited to the Employee Provident Fund Organisation(EPF) within the 15th of this month.' placement='right' ><Icon icon='info' color='dark' /></Tooltips></Label>
                      <FormGroup id='deductionCycle'>
                        <Input
                          disabled
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.deductionCycle}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.deductionCycle}
                          invalidFeedback={employeesProvidentFundForm.errors.deductionCycle}
                          validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div>
                    <br />
                    <div className='col-8 mt-4'>
                      <FormGroup id='employeeContributionRate' label='Employee Contribution Rate' >
                        <Select
                          disabled
                          ariaLabel=''
                          list={[
                            { value: '12 % of actual PF Wage', text: '12 % of actual PF Wage' }
                          ]}
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.employeeContributionRate}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.employeeContributionRate}
                          invalidFeedback={employeesProvidentFundForm.errors.employeeContributionRate}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-8 mt-4'>
                      <Label>Employer Contribution Rate</Label><Button size={'sm'} style={{ float: 'right' }} color='link' onClick={() => setIsSplitUpOpen(!isSplitUpOpen)}>View SplitUp</Button>

                      <Collapse isOpen={isSplitUpOpen} className="dropdown-menu">
                        <CardBody>
                          <h6>CONTRIBUTION RATE <Icon icon='Close' style={{ float: "right" }} onClick={() => setIsSplitUpOpen(false)}></Icon></h6>
                          <table className='table table-border'>
                            <thead>
                              <tr>
                                <th>SUB COMPONENTS</th>
                                <th>EMPLOYER'S CONTRIBUTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Employees' Provident Fund (EPF)</td>
                                <td>3.67% of PF Wage</td>
                              </tr>
                              <tr>
                                <td>Employees' Pension Scheme</td>
                                <td>8.33% of PF Wage <Tooltips title='Maximum Employer Contribution for EPS is ₹1250'><Icon icon='info'></Icon></Tooltips></td>
                              </tr>
                            </tbody>
                          </table>
                        </CardBody>
                      </Collapse>
                      <FormGroup id='employerContributionRate'>
                        <Select
                          ariaLabel=''
                          list={[{ value: '12 % of actual PF Wage', text: '12 % of actual PF Wage' },
                          { value: 'Restrict contribution to ₹15000 of PF Wage', text: 'Restrict contribution to ₹15000 of PF Wage' }
                          ]}
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.employerContributionRate}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.employerContributionRate}
                          invalidFeedback={employeesProvidentFundForm.errors.employerContributionRate}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-lg-6 mt-4 ms-3'>
                      <ButtonGroup>
                        <Checks
                          type='checkbox'
                          id='isLabourWelfareFund'
                          label='Include employers contribution in the CTC'
                          onClick={() => setRate(!rate)}
                          checked={rate}
                        />
                      </ButtonGroup>
                    </div>
                    {rate ?
                      <>
                        <div className="col-12 mt-2 ms-4">
                          <Checks
                            id='flexCheckDefault'
                            label="Include employer's EDLI contribution in the CTC"
                            name='defaultCheck'
                            onChange={doCheck}
                            checked={doChecked}
                          />
                        </div>
                        <div className="col-12 mt-2 ms-4">
                          <Checks
                            id='flexCheckDefault'
                            label='include admin charges in the CTC'
                            name='defaultCheck'
                            onChange={Check}
                            checked={checked}
                          />
                        </div>
                      </> : null
                    }
                    <div className='col-lg-6 mt-4 ms-3'>
                      <ButtonGroup>
                        <Checks
                          id='flexCheckDefault'
                          label='Override PF contribution rate at employee level'
                          name='defaultCheck'
                          onClick={() => setEditCtc(!editCtc)}
                          onChange={edit}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                  <br />
                  <div className='h5 fw-bold ms-2'>PF Configuration when LOP Applied</div>
                  <div className='col-12 mt-3 ms-3'>
                    <Checks
                      type='checkbox'
                      id='applied'
                      label={
                        <>
                          <b className='ms-2'>Pro-rate Restricted PF Wage</b>
                          <br />&nbsp;
                          <span className='mt-4 ms-2'>
                            PF contribution will be pro-rated based on the number of days worked by the employee.
                          </span>
                        </>
                      }
                      onChange={setApply}
                      checked={applied}
                    />
                  </div>
                  <br />
                  <div className='col-12 mt-3 ms-3'>
                    <Checks
                      type='checkbox'
                      id='pay'
                      label={
                        <>
                          <b className='ms-2'>Consider all applicable salary components if PF wage is less than ₹15,000 after Loss of Pay</b>
                          <br />
                          <div className='mt-2 ms-4'>
                            <span>
                              PF wage will be computed using the salary earned in that particular month <br />(based on LOP) rather than the actual amount mentioned in the salary structure.
                            </span>
                          </div>
                        </>
                      }
                      onChange={paid}
                      checked={pay}
                    />
                  </div>
                  <br />
                  <div className='h5 fw-bold ms-2'>ABRY eligibility</div>
                  <div className='col-12 mt-3 ms-3'>
                    <Checks
                      type='checkbox'
                      id='checkOn'
                      label={
                        <>
                          <b className='ms-2'>Eligible for ABRY Scheme</b>
                          <br />
                          <div className='mt-2 ms-4'>
                            <span>
                              The EPF contribution of both the employee and the employer (with a few exceptions) <br /> will be paid by the Government for eligible employees who receive up to ₹ 15,000 <br />in monthly wages.
                            </span>
                          </div>
                        </>
                      }
                      onChange={radioCheck}
                      checked={checkOn}
                    />
                  </div>
                  <br />
                  {checkOn ?
                    <div className='col-12'>
                      <Label className='h5 fw-bold ms-2'>Contribution Type</Label>
                      <div className='col-12 mt-3 ms-4'>
                        <Checks
                          type='radio'
                          name='flexRadioDisabled'
                          id='flexRadioDefault1-2'
                          label='Both Employee and Employer'
                          value='third'
                          onChange={flexRadios.handleChange}
                          checked={flexRadios.values.flexRadioDisabled}
                        />
                        <Checks
                          type='radio'
                          name='flexRadioDisabled'
                          id='flexRadioDefault2-2'
                          label='Only Employee'
                          value='fourth'
                          onChange={flexRadios.handleChange}
                          checked={flexRadios.values.flexRadioDisabled}
                        />
                      </div>
                    </div>
                    : null}
                </CardBody>                
              </div>

              <div className='col-4 mt-3'>
                <Alert color='warning' isLight>
                  <div className='row'>
                    <ButtonGroup>
                      <AlertHeading className='col-lg-12 fs-5 mt-2'>
                        Sample EPF Calculation <br />
                        <div className='text-muted fs-6 mt-2'> Let's assume the PF wage is ₹ 20,000. The breakup of contribution will be:</div>
                      </AlertHeading>
                    </ButtonGroup>
                  </div>
                  <div className='col-12'>
                    <Card stretch className='mt-2'>
                      <CardBody>
                        <FormGroup title='Head Office'>
                          <div className='row'>
                            <div className='h6 fw-bold'>
                              Employees' Contribution
                            </div>
                          </div>
                          <br />
                          <div className='row'>
                            <div className='col-md-8 col-12'>
                              <p className='text-muted h6'>
                                EPF (12% of 20000)
                              </p>
                            </div>

                            <div className='col-md-4 col-12'>
                              <p className='text-muted h6'>₹ 2400</p>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='h6 fw-bold'>
                                Employees' Contribution
                              </div>
                            </div>
                            <div className='col-md-8 col-12 mt-4'>
                              <p className='text-muted h6'>
                                EPS (8.33% of 20000 (Max of ₹ 15,000))	</p>
                            </div>
                            <div className='col-md-4 col-12 mt-4'>
                              <p className='text-muted h6'>₹ 1250</p>
                            </div>
                            <div className='col-md-8 col-12 mt-4'>
                              <p className='text-muted h6'>EPF (12% of 20000 - EPS)</p>
                            </div>
                            <div className='col-md-4 col-12 mt-4'>
                              <p className='text-muted h6'>₹ 1150</p>
                            </div>
                            <hr />
                            <div className='col-md-8 col-12 mt-1'>
                              <p className=' h6'>Total</p>
                            </div>
                            <div className='col-md-4 col-12 mt-1'>
                              <p className=' h6'>₹ 2400</p>
                            </div>
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-12 mt-1'>
                    <Icon icon='Lightbulb' size={'lg'} /> Do you want to preview EPF calculation for multiple cases, based on the preferences you have configured ?
                  </div>
                  <div className='col-12'>
                    <Button isLink>
                      <Icon icon='RemoveRedEye' size={'lg'} onClick={() => setPreviewEpf(true)} /><Button
                        color='link' onClick={() => setPreviewEpf(true)}>Preview EPF Calculation</Button>
                    </Button>
                  </div>
                </Alert>
              </div>
            </div>
          </CardBody>
          <CardFooter borderSize={1}>
            <CardFooterLeft>
              <Button color='info' type="submit" isDisable={!employeesProvidentFundForm.isValid && !employeesProvidentFundForm.submitCount}>Enable</Button>
            </CardFooterLeft>
            <CardFooterRight>
              <Button isOutline color='info' isLight onClick={() => navigate(-1)}> Cancel</Button>
            </CardFooterRight>
          </CardFooter>
        </Card>

        <Modal isOpen={previewEpf} setIsOpen={setPreviewEpf} titleId='addAcademicPeriod' isCentered isAnimation={true} size={'lg'}>
          <></>
          <ModalBody>
            <div className="row">
              <h5 className='h5 text-start fs-5 col-lg-12'><b>EPF Sample Calculation</b> <Icon icon='Close' style={{ float: 'right' }} onClick={() => setPreviewEpf(false)}></Icon></h5>
            </div>
            <hr />
            <div className='col-12'>
              <p className='mt-2'>Let's assume the salary packages considered for EPF is as shown as below, the calculation is based on the settings we’ve configured
              </p>
              <div className='row'>
                <div className='col-lg-12'>
                  <Accordion id='accSample-2' isFlush shadow='none'>
                    <AccordionItem
                      id='actor1-2'
                      title='Show current configuration '
                    >
                      <div className='row'>
                        <div className='col-lg-6 text-start'>
                          <h6 className='text-success'>PF CONTRIBUTION SETTINGS</h6>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-md-8 col-12'>
                          <p className='text-muted h6'>
                            Employer Contribution :
                          </p>
                        </div>
                        <div className='col-md-4 col-12'>
                          <p className='text-muted h6'>12% of Actual PF Wage</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-8 col-12 '>
                          <p className='text-muted h6'>
                            Employee Contribution :
                          </p>
                        </div>

                        <div className='col-md-4 col-12'>
                          <p className='text-muted h6'>12% of Actual PF Wage</p>
                        </div>
                      </div>
                      <div className='row mt-4'>
                        <div className='col-lg-6 text-start text-success'>
                          <h6>LOP CONTRIBUTION</h6>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-md-8 col-12'>
                          <p className='text-muted h6'>
                            Pro-rate Restricted PF Wage :
                          </p>
                        </div>

                        <div className='col-md-4 col-12'>
                          <p className='text-muted h6'> Disabled</p>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-md-8 col-12'>
                          <p className='text-muted h6'>
                            Consider all components when PF wage less than ₹15,000 after LOP :
                          </p>
                        </div>

                        <div className='col-md-4 col-12'>
                          <p className='text-muted h6'>Enabled</p>
                        </div>
                      </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <h5>Salary and EPF Calculation</h5>
                </div>
                <div className='col-lg-6 text-end'>
                  <ButtonGroup>
                    <Checks
                      className=' '
                      id='flexCheckDefault'
                      name='defaultCheck'
                      onChange={flexChecks.handleChange}
                      checked={flexChecks.values.defaultCheck}
                    />
                    <p>With 15 days LOP</p>
                  </ButtonGroup>
                </div>
              </div>
              <table className='table table-border'>
                <thead>
                  <tr>
                    <th>SALARY COMPONENTS</th>
                    <th>PACKAGE 1</th>
                    <th>PACKAGE 2</th>
                    <th>PACKAGE 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Basic
                      <br />
                      <p className='text-muted'>Always considered for EPF</p>
                    </td>
                    <td>₹ 25000</td>
                    <td>₹ 18000</td>
                    <td>₹ 12000</td>
                  </tr>
                  <tr>
                    <td>Transport Allowance
                      <br />
                      <p className='text-muted'>Considered for EPF only when PF wage less than ₹ 15,000
                      </p>
                    </td>
                    <td>₹ 4000</td>
                    <td>₹ 2000</td>
                    <td>₹ 1500</td>
                  </tr>
                  <tr>
                    <td>Telephone Allowance
                      <br />
                      <p className='text-muted'>Considered for EPF only when PF wage less than ₹ 15,000</p>
                    </td>
                    <td>₹ 3500</td>
                    <td>₹ 3000</td>
                    <td>₹ 1000</td>
                  </tr>
                  <tr>
                    <td>EPF Contribution</td>
                    <td>₹ 3000 <br />12% of 25000</td>
                    <td>₹ 2160  <br /> 12% of 18000 </td>
                    <td>₹ 1740 <br /> 12% of 14500</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalBody>
          <div className='col-md-3 ms-3 '>
            <Button isOutline color='info' onClick={() => setPreviewEpf(false)}>Okay, got it</Button>
          </div>
          <br />
        </Modal >
      </Page>
    </PageWrapper >
  );
};
export default employeesProvidentFund;