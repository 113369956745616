import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import useDarkMode from '../../hooks/useDarkMode';
import { useFormik } from 'formik';
import {
	getBankDetails,
	getBlood,
	getCategory,
	getCurrentDateAndTime,
	getGenderDetailsList,
	getStates,
	getStatesAndCity,
	getTransportAreaMaster,
	onlyAllowNumber,
	profilePic,
	profilePicUpload,
} from '../../services/common.service';
import { getLicenseKey } from '../../services/application.settings';
import { TableLoader, showLoader } from '../../services/loader.services';
import { addStaffDetails, getStaffDegree, getStaffSalaryDetails } from '../../services/staff.service';
import { toasts } from '../../services/toast.service';
import { getUserType } from '../../services/userType.service';
import {
	getDepartmentList,
	getStaffDesignation,
	getWorkLocationsList,
} from '../../services/setting.services';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Popovers from '../bootstrap/Popovers';
import classNames from 'classnames';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Input from '../bootstrap/forms/Input';
import Button, { ButtonGroup } from '../bootstrap/Button';
import SearchableSelect from '../../common/components/SearchableSelect';
import Checks from '../bootstrap/forms/Checks';
import AlertService from '../../services/AlertService';
import { getSalaryTemplateList } from '../../services/salary.service';
import Icon from '../icon/Icon';
import InputGroup, { InputGroupText } from '../bootstrap/forms/InputGroup';

function CreateStaff() {

	const [step, setStep] = useState<number>(0);
	const totalSteps: number = 5;

	const handleNext = () => {
		if (step < totalSteps) {
			setStep(step + 1);
		}
	};

	const handlePrevious = () => {
		setStep(step - 1);
	};

	const handleStepClick = (newStep: any) => {
		setStep(newStep);
	};

	const { userAccountId, organisationDetailsId } = useContext(AuthContext);

	useEffect(() => {

		getGender();
		getBloodList();
		getCategoryDetails();
		getTransportAreaMasterList();
		getStatesList();
		getPermAddr_State();
		getUserTypeList();
		getStaffDegreeDetails();
		getDepartment();
		getCityList(31);
		getPermAddr_StateAndCity(31);
		getWorkLocations();
		getSalaryTemplate(organisationDetailsId, 0);
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [commAddr_StateId, setCommAddr_StateId] = useState<any>({
		value: 31,
		label: 'Tamil Nadu',
	});
	const [commAddr_CityId, setCommAddr_CityId] = useState<any>('');

	const [permAddr_StateId, setPermAddr_StateId] = useState<any>({
		value: 31,
		label: 'Tamil Nadu',
	});
	const [permAddr_CityId, setPermAddr_CityId] = useState<any>('');
	const [permAddr_FlatNo, setPermAddr_FlatNo] = useState<any>('');
	const [permAddr_Street, setPermAddr_Street] = useState<any>('');
	const [permAddr_Area, setPermAddr_Area] = useState<any>('');
	const [permAddr_Pincode, setPermAddr_Pincode] = useState<any>('');
	const [isChecked, setIsChecked] = useState(false);

	const [genderData, setGenderData] = useState<any>([]);
	const [bloodGroupData, setBloodGroupData] = useState<any>([]);
	const [categoryData, setCategoryData] = useState<any>([]);
	const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([]);
	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffDesignationData, setStaffDesignationData] = useState<any>([]);
	const [staffDegreeData, setStaffDegreeData] = useState<any>([]);
	const [workLocationId, setWorkLocationId] = useState<any>('');
	const [salaryTemplateData, setSalaryTemplateData] = useState<any>([]);
	const [staffSalaryDetails, setStaffSalaryDetails] = useState<any>([])

	const [statesData, setStatesData] = useState<any>([]);
	const [cityData, setCityData] = useState<any>([]);
	const [workLocationData, setWorkLocationData] = useState<any>([]);

	const [userTypeId, setUserTypeId] = useState<any>('');
	const [isJoiningDetails, setIsJoiningDetails] = useState('isDefault');

	const [isCollegeTransport, setTransportId] = useState('');
	const [isTransportDetails, setIsTransportDetails] = useState('isTransportDetails');

	const [martialStatus, setMartialStatus] = useState('');
	const [isMartialStatus, setIsMartialStatus] = useState('isMaritalStatus');

	const [departmentData, setDepartmentData] = useState<any>([]);
	const [permAddr_StateData, setPermAddr_StateData] = useState<any>([]);
	const [permAddr_CityData, setPermAddr_CityData] = useState<any>([]);

	const [passwordVisible, isPasswordVisible] = useState(false);
	const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>(0);
	const [genderId, setGenderId] = useState<any>('');
	const [bloodGroupId, setBloodGroupId] = useState<any>('');
	const [departmentId, setDepartmentId] = useState<any>('');
	const [staffDesignationId, setStaffDesignationId] = useState<any>('');
	const [category, setCategory] = useState<any>('');
	const [marriageDateStamp, setMarriageDateStamp] = useState<any>('');
	const [spouseName, setSpouseName] = useState<any>('');
	const [accountTypeId, setAccountTypeId] = useState<any>(Number);
	const [salaryTemplateId, setSalaryTemplateId] = useState<any>('');
	const [annualCTC, setAnnualCTC] = useState<any>('');
	const [totalMonthlyAmount, setTotalMonthlyAmount] = useState<any>(0)
	const [totalAnnualAmount, setTotalAnnualAmount] = useState<any>(0)
	const [iFSCCode, setIFSCCode] = useState<any>('');
	const [bankName, setBankName] = useState<any>('');
	const [branch, setBranch] = useState<any>('');

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);
	const [dataStatus, setDataStatus] = useState(false)

	const addStaffForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			prefix: '',
			firstname: '',
			middlename: '',
			lastname: '',
			genderId: '',
			contactNumber: '',

			bloodGroupId: '',
			dateOfBirth: '',
			qualification1: '',
			panNumber: '',
			// pfNumber: '',
			aadharCardNumber: '',
			category: '',

			isCollegeTransport: '',
			transportAreaMasterId: '',
			martialStatus: '',
			marriageDateStamp: '',
			spouseName: '',
			//login
			email: '',
			password: '',
			confirmpassword: '',
			emergencyContactPhone: '',
		},
		validate: (values) => {
			const errors: {
				prefix?: string;
				firstname?: string;
				genderId?: string;
				contactNumber?: string;
				email?: string;
				dateOfBirth?: string;
				emergencyContactPhone?: string;
				aadharCardNumber?: string;
				password?: string;
				confirmpassword?: string;
				panNumber?: string;
			} = {};
			if (!values.prefix) {
				errors.prefix = 'Required';
			}
			if (!values.firstname) {
				errors.firstname = 'Required';
			}
			if (!genderId) {
				errors.genderId = 'Required';
			}
			if (!values.contactNumber) {
				errors.contactNumber = 'Required';
			} else if (values.contactNumber.length < 10 || values.contactNumber.length > 10) {
				errors.contactNumber = 'Must be 10 Numbers';
			} else if (!/^[6-9]\d{9}$/i.test(values.contactNumber)) {
				errors.contactNumber = 'Please enter valid mobile number';
			}

			if ((values.emergencyContactPhone && values.emergencyContactPhone.length < 10) ||
				values.emergencyContactPhone.length > 10
			) {
				errors.emergencyContactPhone = 'Must be 10 Numbers';
			} else if (values.emergencyContactPhone && !/^[6-9]\d{9}$/i.test(values.emergencyContactPhone)) {
				errors.emergencyContactPhone = 'Please enter valid mobile number';
			}

			if (!/^\d{12}$/.test(values.aadharCardNumber) && values.aadharCardNumber != '') {
				errors.aadharCardNumber = 'Must be 12 Numbers';
			}
			if (!values.dateOfBirth) {
				errors.dateOfBirth = 'Required';
			}
			if (!values.email) {
				errors.email = 'Required';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'Invalid email address';
			}
			if (values.password && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i.test(values.password)) {
				errors.password =
					'Password must be 8-16 characters, containing at least 1 special character, 1 capital letter, 1 small letter, and 1 number.';
			}
			if (values.confirmpassword && values.password !== values.confirmpassword) {
				errors.confirmpassword = 'Passwords do not match.';
			}
			if (!values.panNumber) {
				errors.panNumber = 'Required';
			} else if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(values.panNumber) && values.panNumber != '') {
				errors.panNumber = 'Enter a valid PAN num - AAAAA0000A';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			handleNext();
		},
	});

	const addressForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			//Address
			commAddr_FlatNo: '',
			commAddr_Street: '',
			commAddr_Area: '',
			commAddr_StateId: 31,
			commAddr_CityId: '',
			commAddr_Pincode: '',
			permAddr_FlatNo: '',
			permAddr_Street: '',
			permAddr_Area: '',
			permAddr_StateId: 31,
			permAddr_CityId: '',
			permAddr_Pincode: '',
		},
		validate: (values: any) => {
			const errors: {
				commAddr_FlatNo?: string;
				commAddr_StateId?: string;
				commAddr_CityId?: string;
				commAddr_Pincode?: string;
				permAddr_Pincode?: string,
			} = {};
			if (!values.commAddr_FlatNo) {
				errors.commAddr_FlatNo = 'Required';
			}
			if (!commAddr_StateId) {
				errors.commAddr_StateId = 'Required';
			}
			if (!commAddr_CityId) {
				errors.commAddr_CityId = 'Required';
			}
			if (!values.commAddr_Pincode) {
				errors.commAddr_Pincode = 'Required';
			} else if (!/^\d{6}$/.test(values.commAddr_Pincode)) {
				errors.commAddr_Pincode = 'Must be 6 Numbers';
			}
			if (values.permAddr_Pincode && !/^\d{6}$/.test(values.permAddr_Pincode)) {
				errors.commAddr_Pincode = 'Must be 6 Numbers';
			}
			return errors;
		},
		onSubmit: () => {
			handleNext();
		},
	});

	const familyForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			//family
			fatherName: '',
			motherName: '',
			emergencyContactPerson: '',
			//bank
			accountHolderName: '',
			bankName: '',
			accountNumber: '',
			branch: '',
			ifscCode: '',
			accountTypeId: '',
		},
		validate: (values) => { },
		onSubmit: () => {
			handleNext();
		},
	});

	const joiningForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffDesignationId: '',
			userTypeId: 0,
			dateOfJoining: getCurrentDateAndTime('date'),
			appointmentLt: '',
			approvalNo: '',
			departmentId: '',
			expertise: '',
			jobType: '',
			experienceInYear: '',
			badge: '',
			badgeExpiryDate: '',
			licenseNumber: '',
			licenseExpiryDate: '',
			workLocationId: '',
			staffQualificationDetails: [],
		},
		validate: (values) => {
			const errors: {
				userTypeId?: any;
				dateOfJoining?: string;
				staffDesignationId?: string;
				departmentId?: string;
				workLocationId?: string;
				licenseNumber?: string;
			} = {};
			if (!userTypeId) {
				errors.userTypeId = 'Required';
			}
			if (!values.dateOfJoining) {
				errors.dateOfJoining = 'Required';
			}
			if (!workLocationId) {
				errors.workLocationId = 'Required';
			}

			if (userTypeId == 7 || userTypeId == 8) {
				if (!staffDesignationId) {
					errors.staffDesignationId = 'Required';
				}
			}
			if (userTypeId == 7) {
				if (!departmentId) {
					errors.departmentId = 'Required';
				}
			}
			if (userTypeId == 9) {
				if (!values.licenseNumber) {
					errors.licenseNumber = 'Required';
				}
			}
			return errors;
		},
		onSubmit: () => {
			handleNext();
		},
	});

	const salaryInfoForm = useFormik({
		initialValues: {
			salaryTemplateId: '',
			annualCTC: '',
			basicCtcPercentage: '',
			isConsiderForEpfContribution: '',
		},
		validate: (values) => {
			const errors: {
				salaryTemplateId?: string;
				annualCTC?: string;
				isConsiderForEpfContribution?: string;
			} = {};
			if (!salaryTemplateId) {
				errors.salaryTemplateId = 'Required';
			}
			if (!annualCTC) {
				errors.annualCTC = 'Required';
			}
			if (!values.isConsiderForEpfContribution) {
				errors.isConsiderForEpfContribution = 'Required';
			}
			return errors;
		},
		onSubmit: () => { addStaffSubmit() },
	});

	const handleMouseDown = () => {
		isPasswordVisible(true);
	};

	const handleMouseUp = () => {
		isPasswordVisible(false);
	};

	const selectState = (e: any) => {
		let commAddr_StateId = e;
		setCommAddr_StateId(commAddr_StateId);
		if (commAddr_StateId?.value != undefined) {
			getCityList(commAddr_StateId?.value);
		}
	};

	const selectCity = (e: any) => {
		let commAddr_CityId = e;
		setCommAddr_CityId(commAddr_CityId);
	};

	const selectPermAddr_State = (e: any) => {
		let permAddr_StateId = e;
		setPermAddr_StateId(permAddr_StateId);
		if (permAddr_StateId?.value != undefined) {
			getPermAddr_StateAndCity(permAddr_StateId?.value);
		}
	};

	const selectPermAddr_City = (e: any) => {
		let permAddr_CityId = e;
		setPermAddr_CityId(permAddr_CityId);
	};

	const selectUserTypeId = (e: any) => {
		joiningForm.resetForm();
		setStaffDesignationId('');
		setDepartmentId('');
		let userTypeId = e;
		setUserTypeId(userTypeId);
		if (userTypeId?.value != undefined) {
			getStaffDesignationDetails(userTypeId?.value);
		}
		if (userTypeId?.value == '7') {
			setIsJoiningDetails('isTeachingStaff');
		} else if (userTypeId?.value == '8') {
			setIsJoiningDetails('isNonTeachingStaff');
		} else if (userTypeId?.value == '9') {
			setIsJoiningDetails('isDriver');
		} else {
			setIsJoiningDetails('isDefault');
		}
	};

	// const selectTransport = (e: any) => {
	// 	setTransportAreaMasterId(0);
	// 	let isCollegeTransport = e.target.value;
	// 	setTransportId(isCollegeTransport);
	// 	if (isCollegeTransport == 'Yes') {
	// 		setIsTransportDetails('isAreaDetails');
	// 	} else {
	// 		setIsTransportDetails('isTransportDetails');
	// 	}
	// };

	const selectMaritalStatus = (e: any) => {
		let martialStatus = e.target.value;
		setMartialStatus(martialStatus);
		if (martialStatus == 'Married') {
			setIsMartialStatus('isMarried');
		} else {
			setSpouseName('');
			setMarriageDateStamp('');
			setIsMartialStatus('isMaritalStatus');
		}
	};

	const [salaryTemplateListById, setSalaryTemplateListById] = useState<any>([])

	function selectSalaryTemplate(salaryTemplateId: any) {
		setStaffSalaryDetails([])
		setDataStatus(false)
		setSalaryTemplateId(salaryTemplateId);
		if (salaryTemplateId?.value != undefined) {
			let salaryTemplate = salaryTemplateData.filter(
				(item: any) => item.salaryTemplateId == salaryTemplateId,
			);
			setSalaryTemplateListById(salaryTemplate[0]);
		}
	}

	function multiply(annualCTC: any) {
		setAnnualCTC(Number(annualCTC))
	}

	function getSalaryTemplate(organisationDetailsId: any, salaryTemplateId: any) {
		getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.salaryTemplateList;
					if (data != undefined) {
						setSalaryTemplateData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setSalaryTemplateData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffSalaryList(salaryTemplateId: any, annualCTC: any) {
		setStaffSalaryDetails([])
		setDataStatus(true)
		getStaffSalaryDetails(salaryTemplateId, annualCTC, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffSalaryDetails;
					if (data != undefined) {
						setStaffSalaryDetails(data)
						let monthlyAmount = data.map((item: any) => item.monthlyAmount)
						let annualAmount = data.map((item: any) => item.annualAmount)
						let totalMonthlyAmount = (monthlyAmount.reduce((a: any, v: any) => a = a + v, 0))
						let totalAnnualAmount = (annualAmount.reduce((a: any, v: any) => a = a + v, 0))
						setTotalMonthlyAmount(totalMonthlyAmount.toFixed(2));
						setTotalAnnualAmount(totalAnnualAmount.toFixed(2));
						setDataStatus(false)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStaffSalaryDetails([])
					setDataStatus(false)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
					setDataStatus(false)
				}
			}, error => {
				toasts(error, "Error")
				setDataStatus(false)
			}
		)
	}

	function setAddStaffDetails() {

		return {
			organisationDetailsId: organisationDetailsId,
			prefix: addStaffForm.values.prefix == '' ? null : addStaffForm.values.prefix,
			firstname: addStaffForm.values.firstname == '' ? null : addStaffForm.values.firstname,
			middlename: addStaffForm.values.middlename == '' ? null : addStaffForm.values.middlename,
			lastname: addStaffForm.values.lastname == '' ? null : addStaffForm.values.lastname,
			genderId: genderId?.value == '' ? 0 : genderId?.value,
			contactNumber: addStaffForm.values.contactNumber == '' ? 0 : addStaffForm.values.contactNumber,
			userTypeId: userTypeId?.value == undefined ? 0 : userTypeId?.value,
			bloodGroupId: bloodGroupId?.value == '' ? 0 : bloodGroupId?.value,
			dateOfBirth: addStaffForm.values.dateOfBirth == '' ? null : addStaffForm.values.dateOfBirth,
			qualification1: addStaffForm.values.qualification1 == '' ? null : addStaffForm.values.qualification1,
			panNumber: addStaffForm.values.panNumber == '' ? null : addStaffForm.values.panNumber,
			// pfNumber: addStaffForm.values.pfNumber == '' ? null : addStaffForm.values.pfNumber,
			aadharCardNumber: addStaffForm.values.aadharCardNumber == '' ? null : addStaffForm.values.aadharCardNumber,
			category: category?.label != undefined ? category?.label : null,

			isCollegeTransport: isCollegeTransport == '' ? null : isCollegeTransport,
			transportAreaMasterId: transportAreaMasterId?.value == 0 ? 0 : transportAreaMasterId?.value,
			martialStatus: martialStatus == '' ? null : martialStatus,
			marriageDateStamp: marriageDateStamp == '' ? null : marriageDateStamp,
			spouseName: spouseName == '' ? null : spouseName,
			emergencyContactPhone: addStaffForm.values.emergencyContactPhone == '' ? null : addStaffForm.values.emergencyContactPhone,
			//login
			email: addStaffForm.values.email == '' ? null : addStaffForm.values.email,
			password: addStaffForm.values.password == '' ? null : addStaffForm.values.password,
			confirmpassword: addStaffForm.values.confirmpassword == '' ? null : addStaffForm.values.confirmpassword,

			//family
			fatherName: familyForm.values.fatherName == '' ? null : familyForm.values.fatherName,
			motherName: familyForm.values.motherName == '' ? null : familyForm.values.motherName,
			emergencyContactPerson: familyForm.values.emergencyContactPerson == '' ? null : familyForm.values.emergencyContactPerson,

			//bank
			accountHolderName: familyForm.values.accountHolderName == '' ? null : familyForm.values.accountHolderName,
			bankName: bankName == '' ? null : bankName,
			accountNumber: familyForm.values.accountNumber == '' ? null : familyForm.values.accountNumber,
			branch: branch == '' ? null : branch,
			ifscCode: iFSCCode == '' ? null : iFSCCode,
			accountTypeId: accountTypeId?.value != undefined ? accountTypeId?.value : null,
			paymentTypeId: 1,
			//Address
			commAddr_FlatNo: addressForm.values.commAddr_FlatNo == '' ? null : addressForm.values.commAddr_FlatNo,
			commAddr_Street: addressForm.values.commAddr_Street == '' ? null : addressForm.values.commAddr_Street,
			commAddr_Area: addressForm.values.commAddr_Area == '' ? null : addressForm.values.commAddr_Area,
			commAddr_StateId: commAddr_StateId?.value != undefined ? commAddr_StateId?.value : null,
			commAddr_CityId: commAddr_CityId?.value != undefined ? commAddr_CityId?.value : null,
			commAddr_Pincode: addressForm.values.commAddr_Pincode == '' ? null : addressForm.values.commAddr_Pincode,

			permAddr_FlatNo: permAddr_FlatNo ? permAddr_FlatNo : null,
			permAddr_Street: permAddr_Street ? permAddr_Street : null,
			permAddr_Area: permAddr_Area ? permAddr_Area : null,
			permAddr_StateId: permAddr_StateId?.value != undefined ? permAddr_StateId?.value : null,
			permAddr_CityId: permAddr_CityId?.value != undefined ? permAddr_CityId?.value : null,
			permAddr_Pincode: permAddr_Pincode ? permAddr_Pincode : null,
			staffDesignationId: staffDesignationId?.value == undefined ? 0 : staffDesignationId?.value,
			dateOfJoining: joiningForm.values.dateOfJoining == '' ? null : joiningForm.values.dateOfJoining,
			appointmentLt: joiningForm.values.appointmentLt == '' ? null : joiningForm.values.appointmentLt,
			approvalNo: joiningForm.values.approvalNo == '' ? null : joiningForm.values.approvalNo,
			departmentId: departmentId?.value == '' ? 0 : departmentId?.value,
			expertise: joiningForm.values.expertise == '' ? null : joiningForm.values.expertise,
			jobType: joiningForm.values.jobType == '' ? null : joiningForm.values.jobType,
			experienceInYear: joiningForm.values.experienceInYear == '' ? null : joiningForm.values.experienceInYear,
			badge: joiningForm.values.badge == '' ? null : joiningForm.values.badge,
			badgeExpiryDate: joiningForm.values.badgeExpiryDate == '' ? null : joiningForm.values.badgeExpiryDate,
			licenseNumber: joiningForm.values.licenseNumber == '' ? null : joiningForm.values.licenseNumber,
			licenseExpiryDate: joiningForm.values.licenseExpiryDate == '' ? null : joiningForm.values.licenseExpiryDate,
			workLocationsId: workLocationId?.value == undefined ? null : workLocationId?.value,
			staffQualificationDetails: [],

			salaryTemplateId: salaryTemplateId?.value ? salaryTemplateId?.value : null,
			annualCTC: annualCTC,
			basicCtcPercentage: salaryInfoForm.values.basicCtcPercentage != '' ? salaryInfoForm.values.basicCtcPercentage : null,
			isConsiderForEpfContribution: salaryInfoForm.values.isConsiderForEpfContribution,

			createdBy: userAccountId,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function addStaffSubmit() {
		showLoader(true);
		if (salaryInfoForm.isValid) {
			let addStaffPostData = setAddStaffDetails();
			addStaffDetails(
				addStaffPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						let staffDetailsId = data.data.staffDetailsId;
						if (selectedFile != undefined) {
							onUpload(staffDetailsId);
						}
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsNavigate(`../userManagement/staffList`);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
						setIsNavigate(null);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
						setIsNavigate(null);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
					setIsNavigate(null);
				},
			);
		} else if (salaryInfoForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
			setIsNavigate(null);
		}
	}

	const [selectedFile, setSelectedFile] = useState<any>();

	function onUpload(studentOrStaffDetailsId: any) {
		const fileData = new FormData();
		fileData.append('profilePic', selectedFile, selectedFile.name);
		fileData.append('licenseKey', getLicenseKey.toString());
		fileData.append('userAccountId', userAccountId);
		fileData.append('studentOrStaffDetailsId', studentOrStaffDetailsId);
		fileData.append('userTypeId', '7');

		profilePicUpload(
			fileData,
			(response) => {
				console.log('Profile pic upload');
			},
			(error) => {
				console.log('Profile pic not upload');
			},
		);
	}

	function getGender() {
		getGenderDetailsList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.genderDetails;
					if (data != undefined) {
						setGenderData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setGenderData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBloodList() {
		getBlood(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.blood;
					if (data != undefined) {
						setBloodGroupData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setBloodGroupData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getCategoryDetails() {
		getCategory(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.category;
					if (data != undefined) {
						setCategoryData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setCategoryData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getTransportAreaMasterList() {
		getTransportAreaMaster(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.transportAreaMaster;
					if (data != undefined) {
						setTransportAreaMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setTransportAreaMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStatesList() {
		getStates(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity;
					if (data != undefined) {
						setStatesData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStatesData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getCityList(stateId: any) {
		getStatesAndCity(
			stateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity[0]['city'];
					if (data != undefined) {
						setCityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setCityData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getPermAddr_State() {
		getStates(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity;
					if (data != undefined) {
						setPermAddr_StateData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setPermAddr_StateData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getPermAddr_StateAndCity(stateId: any) {
		getStatesAndCity(
			stateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity[0]['city'];
					if (data != undefined) {
						setPermAddr_CityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setPermAddr_CityData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getWorkLocations() {
		getWorkLocationsList(
			organisationDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workLocationsList;
					if (data != undefined) {
						setWorkLocationData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setWorkLocationData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						setUserTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffDesignationDetails(userTypeId: number) {
		getStaffDesignation(
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffDesignation;
					if (data != undefined) {
						setStaffDesignationData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStaffDesignationData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffDegreeDetails() {
		getStaffDegree(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffDegree;
					if (data != undefined) {
						setStaffDegreeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStaffDegreeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getDepartment() {
		getDepartmentList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.departmentList;
					if (data != undefined) {
						setDepartmentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setDepartmentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBankDetailsByIFSCCode(ifscCode: any) {
		getBankDetails(ifscCode,
			(response) => {
				setBranch(response.data.BRANCH);
				setBankName(response.data.BANK);
			},
			(error) => {
				toasts("IFSC Not Found", 'Error');
				setBranch('');
				setBankName('');
			},
		);
	}


	function address(e: any) {
		setIsChecked(e.target.checked);
		if (e.target.checked == true) {
			setPermAddr_FlatNo(addressForm.values.commAddr_FlatNo);
			setPermAddr_Street(addressForm.values.commAddr_Street);
			setPermAddr_Area(addressForm.values.commAddr_Area);
			setPermAddr_Pincode(addressForm.values.commAddr_Pincode);
			setPermAddr_StateId(commAddr_StateId);
			setPermAddr_CityId(commAddr_CityId);
			if (commAddr_StateId?.value != undefined) {
				getPermAddr_StateAndCity(commAddr_StateId?.value);
			}
		} else {
			setPermAddr_FlatNo('');
			setPermAddr_Street('');
			setPermAddr_Area('');
			setPermAddr_Pincode('');
			setPermAddr_StateId({ value: 31, label: 'Tamil Nadu' });
			setPermAddr_CityId('');
		}
	}

	const titles = ['General Info', 'Family Info', 'Address Info', 'Joining Info', 'Salary Info'];

	const handleFileChange = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile(e.target.files[0]);
		}
	};

	const selectIfsc = (e: any) => {
		let iFSCCode = e.target.value
		setIFSCCode(iFSCCode)
	}

	const handleDeleteAvatar = () => {
		setSelectedFile(null);
		const fileInput = document.getElementById('fileInput') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const accountTypeData = [
		{ accountTypeId: 1, accountType: 'Current' },
		{ accountTypeId: 2, accountType: 'Savings' },
	];

	return (
		<PageWrapper title='Create Staff'>
			<Page container='fluid'>
				<Card stretch>
					<CardHeader>
						<CardLabel icon='Assignment' color='info'>
							{[...Array(totalSteps)].map((_, index) => (
								<CardTitle
									key={index}
									className={index !== step ? 'd-none' : undefined}>
									{titles[step]}
								</CardTitle>
							))}
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='wizard-progress position-relative'>
							<div className='progress'>
								<div
									className='progress-bar bg-primary'
									role='progressbar'
									style={{ width: `${(100 / (totalSteps - 1)) * step}%` }}
									aria-valuenow={(100 / (totalSteps - 1)) * step}
									aria-valuemin={0}
									aria-valuemax={100}
									aria-label='progress'
								/>
							</div>
							{[...Array(totalSteps)].map((child: any, index: any) => (
								<Popovers key={index} desc={titles[index]} trigger='hover'>
									<button
										type='button'
										className={classNames(
											'wizard-progress-btn',
											'position-absolute p-0 top-0',
											'translate-middle',
											'btn btn-sm',
											{
												[`btn-primary`]: step >= index,
												[`btn-${themeStatus}`]: step < index,
											},
											'rounded-pill',
										)}
										style={{
											left: `${(100 / (totalSteps - 1)) * index}%`,
										}}
										onClick={() => {
											handleStepClick(index);
										}}>
										{index + 1}
									</button>
								</Popovers>
							))}
						</div>

						{step == 0 && (
							<Card shadow='none'>
								<form noValidate onSubmit={addStaffForm.handleSubmit}>
									<div className='d-grid gap-4'>
										<div className='row g-4'>
											<div className='col-12'>
												<div className='row g-4 align-items-center'>
													<div className='row col-6 g-4'>
														<div className='col-lg-3 '>
															<FormGroup
																id='prefix'
																label='Prefix'
																isFloating>
																<Select
																	ariaLabel=''
																	placeholder='Select Prefix'
																	onChange={
																		addStaffForm.handleChange
																	}
																	value={
																		addStaffForm.values.prefix
																	}
																	onBlur={addStaffForm.handleBlur}
																	isValid={false}
																	isTouched={
																		addStaffForm.touched.prefix
																	}
																	invalidFeedback={
																		addStaffForm.errors.prefix
																	}>
																	<option>Mr.</option>
																	<option>Mrs.</option>
																	<option>Ms.</option>
																	<option>Dr.</option>
																</Select>
															</FormGroup>
														</div>
														<div className='col-lg-9 '>
															<FormGroup
																id='firstname'
																label='First Name'
																isFloating>
																<Input
																	type='text'
																	placeholder='Enter First Name'
																	onChange={
																		addStaffForm.handleChange
																	}
																	value={
																		addStaffForm.values
																			.firstname
																	}
																	onBlur={addStaffForm.handleBlur}
																	isValid={addStaffForm.isValid}
																	isTouched={
																		addStaffForm.touched
																			.firstname
																	}
																	invalidFeedback={
																		addStaffForm.errors
																			.firstname
																	}
																/>
															</FormGroup>
														</div>
														<div className='col-lg-6 mg-t-20 mg-lg-t-0'>
															<FormGroup
																id='middlename'
																label='Middle Name'
																isFloating>
																<Input
																	type='text'
																	placeholder='Enter Middle Name'
																	onChange={
																		addStaffForm.handleChange
																	}
																	value={
																		addStaffForm.values
																			.middlename
																	}
																/>
															</FormGroup>
														</div>
														<div className='col-lg-6 mg-t-20 mg-lg-t-0'>
															<FormGroup
																id='lastname'
																label='Last Name'
																isFloating>
																<Input
																	type='text'
																	placeholder='Enter Last Name'
																	onChange={addStaffForm.handleChange}
																	value={addStaffForm.values.lastname}
																/>
															</FormGroup>
														</div>
													</div>
													<div className='col-6'>
														<div className='col-12'>
															<div className='row g-4 align-items-center'>
																<div className='col-lg-3'>
																	{selectedFile ? (
																		<img
																			style={{
																				borderRadius: '50%',
																			}}
																			src={URL.createObjectURL(
																				selectedFile,
																			)}
																			height='120'
																			width='120'
																		/>
																	) : (
																		<img
																			style={{
																				borderRadius: '50%',
																			}}
																			src={profilePic(1)}
																			height='120'
																			width='120'
																		/>
																	)}
																</div>
																<div className='col-lg-9 mt-3'>
																	<div className='row g-4'>
																		<div className='col-8'>
																			<Input
																				type='file'
																				id='fileInput'
																				autoComplete='photo'
																				ariaLabel='Upload image file'
																				//onChange={(e: any) => { onFileSelected(e, setSelectedFile) }}
																				onChange={
																					handleFileChange
																				}
																			/>
																		</div>
																		<div className='col-12'>
																			<Button
																				color='dark'
																				isLight
																				icon='Delete'
																				onClick={
																					handleDeleteAvatar
																				}>
																				Delete Avatar
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup id='genderId' label='Gender' isFloating>
													<SearchableSelect
														isFloating
														ariaLabel=''
														placeholder='Select Gender'
														onChange={(e: any) => setGenderId(e)}
														value={genderId}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={addStaffForm.touched.genderId}
														invalidFeedback={
															addStaffForm.errors.genderId
														}
														list={genderData.map((data: any) => ({
															value: data.genderId,
															label: data.gender,
														}))}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='contactNumber'
													label='Contact Number'
													isFloating>
													<Input
														type='text'
														placeholder='Contact Number'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.contactNumber}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={
															addStaffForm.touched.contactNumber
														}
														invalidFeedback={
															addStaffForm.errors.contactNumber
														}
														onKeyDown={(e: any) => onlyAllowNumber(e)}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='emergencyContactPhone'
													label='Emergency Contact Number'
													isFloating>
													<Input
														type='text'
														placeholder='Emergency Contact Number'
														onChange={addStaffForm.handleChange}
														value={
															addStaffForm.values
																.emergencyContactPhone
														}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={
															addStaffForm.touched
																.emergencyContactPhone
														}
														invalidFeedback={
															addStaffForm.errors
																.emergencyContactPhone
														}
														onKeyDown={(e: any) => onlyAllowNumber(e)}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='bloodGroupId'
													label='Blood Group'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel=''
														placeholder='Select Blood Group'
														onChange={(e: any) => setBloodGroupId(e)}
														value={bloodGroupId}
														list={bloodGroupData.map((data: any) => ({
															value: data.bloodGroupId,
															label: data.groupName,
														}))}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='qualification1'
													label='Qualification'
													isFloating>
													<Input
														type='text'
														placeholder='Enter Qualification'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.qualification1}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup id='panNumber' label='Pan No' isFloating>
													<Input
														type='text'
														placeholder='Enter Pan No'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.panNumber}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={addStaffForm.touched.panNumber}
														invalidFeedback={addStaffForm.errors.panNumber}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='aadharCardNumber'
													label='Aadhar Card'
													isFloating>
													<Input
														type='text'
														placeholder='Enter Aadhar Card Number'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.aadharCardNumber}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={
															addStaffForm.touched.aadharCardNumber
														}
														invalidFeedback={
															addStaffForm.errors.aadharCardNumber
														}
														onKeyDown={(e: any) => onlyAllowNumber(e)}
													/>
												</FormGroup>
											</div>
											{/* <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="pfNumber" label="PF Account No" isFloating>
                                                    <Input type="text" placeholder="Enter PF Account No"
                                                        onChange={addStaffForm.handleChange}
                                                        value={addStaffForm.values.pfNumber} />
                                                </FormGroup>
                                            </div> */}
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='dateOfBirth'
													label='Date Of Birth'
													isFloating>
													<Input
														type='date'
														placeholder='Date Of Birth'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.dateOfBirth}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={addStaffForm.touched.dateOfBirth}
														invalidFeedback={
															addStaffForm.errors.dateOfBirth
														}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='category'
													label='Category'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel=''
														placeholder='Select Category'
														onChange={(e: any) => setCategory(e)}
														value={category}
														list={categoryData.map((data: any) => ({
															value: data.categoryId,
															label: data.category,
														}))}
													/>
												</FormGroup>
											</div>
											{/* <div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='isCollegeTransport'
													label='Transport'
													isFloating>
													<Select
														ariaLabel=''
														placeholder='Select Transport'
														onChange={selectTransport}
														value={isCollegeTransport}>
														<option value='Yes'>Yes</option>
														<option value='No'>No</option>
													</Select>
												</FormGroup>
											</div> */}

											{/* {isTransportDetails == 'isAreaDetails' ? (
												<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
													<FormGroup
														id='transportAreaMasterId'
														label='Area of Staff'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel=''
															placeholder='Select Area'
															onChange={(e: any) =>
																setTransportAreaMasterId(e)
															}
															value={transportAreaMasterId}
															list={transportAreaMasterData.map(
																(data: any) => ({
																	value: data.transportAreaMasterId,
																	label: data.areaName,
																}),
															)}
														/>
													</FormGroup>
												</div>
											) : null} */}
											<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
												<FormGroup
													id='martialStatus'
													label='Marital Status'
													isFloating>
													<Select
														ariaLabel=''
														placeholder='Select Marital Status'
														onChange={selectMaritalStatus}
														value={martialStatus}>
														<option value='Married'>Married</option>
														<option value='UnMarried'>UnMarried</option>
													</Select>
												</FormGroup>
											</div>
											{isMartialStatus == 'isMarried' ? (
												<>
													<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
														<FormGroup
															id='marriageDateStamp'
															label='Marriage Date'
															isFloating>
															<Input
																type='date'
																placeholder='Enter Marriage Date'
																onChange={(e: any) =>
																	setMarriageDateStamp(
																		e.target.value,
																	)
																}
																value={marriageDateStamp}
															/>
														</FormGroup>
													</div>
													<div className='col-lg-3 mg-t-20 mg-lg-t-15'>
														<FormGroup
															id='spouseName'
															label='Spouse Name'
															isFloating>
															<Input
																type='text'
																placeholder='Enter Spouse Name'
																onChange={(e: any) =>
																	setSpouseName(e.target.value)
																}
																value={spouseName}
															/>
														</FormGroup>
													</div>
												</>
											) : null}
										</div>
										<div className='row g-4'>
											<div className='col-lg-12'>
												<CardLabel icon='Person' color='info'>
													<CardTitle className='h5'>LOGIN INFO</CardTitle>
												</CardLabel>
											</div>
											<div className='col-lg-3'>
												<FormGroup
													id='email'
													label='Enter Email'
													isFloating>
													<Input
														type='text'
														placeholder='Enter Email'
														onChange={addStaffForm.handleChange}
														value={addStaffForm.values.email}
														onBlur={addStaffForm.handleBlur}
														isValid={addStaffForm.isValid}
														isTouched={addStaffForm.touched.email}
														invalidFeedback={addStaffForm.errors.email}
													/>
												</FormGroup>
											</div>
											<div className='col-lg-3'>
												<InputGroup>
													<FormGroup
														id='password'
														label='Enter Password'
														isFloating>

														<Input
															id='password'
															onChange={addStaffForm.handleChange}
															value={addStaffForm.values.password}
															onBlur={addStaffForm.handleBlur}
															isValid={addStaffForm.isValid}
															isTouched={
																addStaffForm.touched.password
															}
															invalidFeedback={
																addStaffForm.errors.password
															}
															type={
																passwordVisible
																	? 'text'
																	: 'password'
															}
														/>
													</FormGroup>
													<Button isOutline color='primary' icon='RemoveRedEye' style={{ height: '46px' }} onMouseDown={handleMouseDown}
														onMouseUp={handleMouseUp}
														onMouseOut={handleMouseUp} />
												</InputGroup>
											</div>
											<div className='col-lg-3'>
												<InputGroup>
													<FormGroup
														id='confirmpassword'
														label='Enter Confirm Password'
														isFloating>

														<Input
															id='confirmpassword'
															onChange={addStaffForm.handleChange}
															value={
																addStaffForm.values.confirmpassword
															}
															onBlur={addStaffForm.handleBlur}
															isValid={addStaffForm.isValid}
															isTouched={
																addStaffForm.touched.confirmpassword
															}
															invalidFeedback={
																addStaffForm.errors.confirmpassword
															}
															type={
																passwordVisible
																	? 'text'
																	: 'password'
															}
														/>

													</FormGroup>
													<Button isOutline color='primary' icon='RemoveRedEye' style={{ height: '46px' }} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp} />

												</InputGroup>
											</div>
										</div>
									</div>
									<div style={{ textAlign: 'right' }} className='mt-4'>
										<Button color='primary' type='submit' isLight>
											Next
										</Button>
									</div>
								</form>
							</Card>
						)}
						{step == 1 && (
							<Card shadow='none' stretch>
								<form noValidate onSubmit={familyForm.handleSubmit}>
									<div className='row g-4'>
										<div className='col-lg-12'>
											<CardLabel icon='PeopleOutline' color='info'>
												<CardTitle className='h5'>FAMILY INFO</CardTitle>
											</CardLabel>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='fatherName'
												label='Father Name'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Father Name'
													onChange={familyForm.handleChange}
													value={familyForm.values.fatherName}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='motherName'
												label='Mother Name'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Mother Name'
													onChange={familyForm.handleChange}
													value={familyForm.values.motherName}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='emergencyContactPerson'
												label='Contact Person'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Contact Person'
													onChange={familyForm.handleChange}
													value={familyForm.values.emergencyContactPerson}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-12'>
											<CardLabel icon='MapsHomeWork' color='info'>
												<CardTitle className='h5'>ACCOUNT INFO</CardTitle>
											</CardLabel>
										</div>
										<div className='col-lg-3'>
											<FormGroup id='ifscCode' label='IFSC Code' isFloating>
												<Input
													type='text'
													placeholder='Enter IFSC Code'
													onInput={selectIfsc}
													value={iFSCCode}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-1'>
											<Button color='primary' onClick={() => getBankDetailsByIFSCCode(iFSCCode)
											}
												isDisable={iFSCCode != '' ? false : true}>
												Search
											</Button>
										</div>
										<div className='col-lg-4'>
											<FormGroup id='branch' label='Branch' isFloating>
												<Input
													type='text'
													placeholder='Enter Branch'
													onChange={familyForm.handleChange}
													value={branch}
													disabled
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup id='bankName' label='Bank Name' isFloating>
												<Input
													type='text'
													placeholder='Enter Bank Name'
													onChange={familyForm.handleChange}
													value={bankName}
													disabled
												/>
											</FormGroup>
										</div>

										<div className='col-4'>
											<FormGroup
												id='accountHolderName'
												label='Account Holder Name'
												isFloating>
												<Input
													aria-placeholder=''
													placeholder='Enter Account Holder Name'
													onChange={familyForm.handleChange}
													onBlur={familyForm.handleBlur}
													value={familyForm.values.accountHolderName}
													isValid={familyForm.isValid}
													invalidFeedback={
														familyForm.errors.accountHolderName
													}
													// isTouched={familyForm.touched.email}
													type='text'
												/>
											</FormGroup>
										</div>

										<div className='col-lg-4'>
											<FormGroup
												id='accountNumber'
												label='Account No'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Account No'
													onChange={familyForm.handleChange}
													value={familyForm.values.accountNumber}
													onKeyDown={(e: any) => onlyAllowNumber(e)}
												/>
											</FormGroup>
										</div>
										<div className='col-4'>
											<FormGroup
												id='accountTypeId'
												label='Account Type'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Account Type'
													onChange={(e: any) => setAccountTypeId(e)}
													value={accountTypeId}
													list={accountTypeData.map((data: any) => ({
														value: data.accountTypeId,
														label: data.accountType,
													}))}
													isValid={familyForm.isValid}
													isTouched={familyForm.touched.accountTypeId}
													invalidFeedback={
														familyForm.errors.accountTypeId
													}
													onBlur={familyForm.handleBlur}
												/>
											</FormGroup>
										</div>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
										className='mt-4'>
										<Button
											color='primary'
											onClick={handlePrevious}
											type='submit'
											isLink>
											Previous
										</Button>
										<Button color='primary' type='submit' isLight>
											Next
										</Button>
									</div>
								</form>
							</Card>
						)}
						{step == 2 && (
							<Card shadow='none' stretch>
								<form noValidate onSubmit={addressForm.handleSubmit}>
									<div className='row g-4'>
										<div className='col-lg-4'>
											<FormGroup
												id='commAddr_FlatNo'
												label='Flat No Or Name'
												isFloating>
												<Input
													type='text'
													placeholder='Flat No or Name'
													onChange={addressForm.handleChange}
													value={addressForm.values.commAddr_FlatNo}
													onBlur={addressForm.handleBlur}
													isValid={addressForm.isValid}
													isTouched={addressForm.touched.commAddr_FlatNo}
													invalidFeedback={
														addressForm.errors.commAddr_FlatNo
													}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='commAddr_Street'
												label='Street'
												isFloating>
												<Input
													type='text'
													placeholder='Street'
													onChange={addressForm.handleChange}
													value={addressForm.values.commAddr_Street}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='commAddr_Area'
												label='Area / Locality'
												isFloating>
												<Input
													type='text'
													placeholder='Area / Locality'
													onChange={addressForm.handleChange}
													value={addressForm.values.commAddr_Area}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='commAddr_StateId'
												label='State'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select State'
													onChange={(e: any) => selectState(e)}
													value={commAddr_StateId}
													list={statesData.map((data: any) => ({
														value: data.stateId,
														label: data.stateName,
													}))}
													onBlur={addressForm.handleBlur}
													isValid={addressForm.isValid}
													isTouched={addressForm.touched.commAddr_StateId}
													invalidFeedback={
														addressForm.errors.commAddr_StateId
													}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup id='commAddr_CityId' label='City' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select City'
													onChange={selectCity}
													value={commAddr_CityId}
													list={cityData.map((data: any) => ({
														value: data.cityId,
														label: data.cityName,
													}))}
													onBlur={addressForm.handleBlur}
													isValid={addressForm.isValid}
													isTouched={addressForm.touched.commAddr_CityId}
													invalidFeedback={
														addressForm.errors.commAddr_CityId
													}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='commAddr_Pincode'
												label='Pincode'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Pincode'
													onChange={addressForm.handleChange}
													value={addressForm.values.commAddr_Pincode}
													onKeyDown={(e: any) => onlyAllowNumber(e)}
													onBlur={addressForm.handleBlur}
													isValid={addressForm.isValid}
													isTouched={addressForm.touched.commAddr_Pincode}
													invalidFeedback={
														addressForm.errors.commAddr_Pincode
													}
												/>
											</FormGroup>
										</div>

										<div className='col-lg-12'>
											<FormGroup id='checkbox'>
												<Checks
													type='checkbox'
													label='Permanent Address Same As Address For Communication Click Checkbox.'
													onChange={address}
													checked={isChecked}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='permAddr_FlatNo'
												label='Flat No Or Name'
												isFloating>
												<Input
													type='text'
													placeholder='Flat No or Name'
													onInput={(e: any) =>
														setPermAddr_FlatNo(e.target.value)
													}
													value={permAddr_FlatNo}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='permAddr_Street'
												label='Street'
												isFloating>
												<Input
													type='text'
													placeholder='Street'
													onInput={(e: any) =>
														setPermAddr_Street(e.target.value)
													}
													value={permAddr_Street}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='permAddr_Area'
												label='Area / Locality'
												isFloating>
												<Input
													type='text'
													placeholder='Area / Locality'
													onInput={(e: any) =>
														setPermAddr_Area(e.target.value)
													}
													value={permAddr_Area}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='permAddr_StateId'
												label='State'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select State'
													onChange={(e: any) => selectPermAddr_State(e)}
													value={permAddr_StateId}
													list={permAddr_StateData.map((data: any) => ({
														value: data.stateId,
														label: data.stateName,
													}))}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup id='permAddr_CityId' label='City' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select City'
													onChange={selectPermAddr_City}
													value={permAddr_CityId}
													list={permAddr_CityData.map((data: any) => ({
														value: data.cityId,
														label: data.cityName,
													}))}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='permAddr_Pincode'
												label='Pincode'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Pincode'
													onInput={(e: any) =>
														setPermAddr_Pincode(e.target.value)
													}
													onChange={addressForm.handleChange}
													value={permAddr_Pincode}
													onKeyDown={(e: any) => onlyAllowNumber(e)}
													onBlur={addressForm.handleBlur}
													isValid={addressForm.isValid}
													isTouched={addressForm.touched.permAddr_Pincode}
													invalidFeedback={
														addressForm.errors.permAddr_Pincode
													}
												/>
											</FormGroup>
										</div>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
										className='mt-4'>
										<Button
											color='primary'
											onClick={handlePrevious}
											type='submit'
											isLink>
											Previous
										</Button>
										<Button color='primary' type='submit' isLight>
											Next
										</Button>
									</div>
								</form>
							</Card>
						)}
						{step == 3 && (
							<Card shadow='none' stretch>
								<form noValidate onSubmit={joiningForm.handleSubmit}>
									<div className='row g-4'>
										<div className='col-lg-12  align-self-center'>
											<CardLabel icon='Group' color='info'>
												<CardTitle className='h5'>
													JOINING DETAILS
												</CardTitle>
											</CardLabel>
										</div>

										<div className='col-8'>
											<FormGroup
												id='workLocationId'
												isFloating
												label='Work Location'>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select Work Location'
													onChange={(e: any) => setWorkLocationId(e)}
													value={workLocationId}
													onBlur={joiningForm.handleBlur}
													isValid={joiningForm.isValid}
													invalidFeedback={
														joiningForm.errors.workLocationId
													}
													isTouched={joiningForm.touched.workLocationId}
													list={workLocationData.map((data: any) => ({
														value: data.workLocationsId,
														label: data.workLocationsName,
													}))}
												/>
											</FormGroup>
										</div>

										<div className='col-lg-4'>
											<FormGroup
												id='userTypeId'
												label='Designation Type'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Select Designation Type'
													onChange={selectUserTypeId}
													onBlur={joiningForm.handleBlur}
													value={userTypeId}
													list={userTypeData.map((data: any) => ({
														value: data.userTypeId,
														label: data.userType,
													}))}
													isValid={joiningForm.isValid}
													isTouched={joiningForm.touched.userTypeId}
													invalidFeedback={joiningForm.errors.userTypeId}
												/>
											</FormGroup>
										</div>

										{isJoiningDetails == 'isTeachingStaff' ||
											isJoiningDetails == 'isNonTeachingStaff' ? (
											<div className='col-lg-4'>
												<FormGroup
													id='staffDesignationId'
													label='Designation'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel=''
														placeholder='Select Designation'
														onChange={(e: any) =>
															setStaffDesignationId(e)
														}
														onBlur={joiningForm.handleBlur}
														value={staffDesignationId}
														list={staffDesignationData.map(
															(data: any) => ({
																value: data.staffDesignationId,
																label: data.designationName,
															}),
														)}
														isValid={false}
														isTouched={
															joiningForm.touched.staffDesignationId
														}
														invalidFeedback={
															joiningForm.errors.staffDesignationId
														}
													/>
												</FormGroup>
											</div>
										) : null}

										{isJoiningDetails == 'isTeachingStaff' ? (
											<>
												<div className='col-lg-4'>
													<FormGroup
														id='departmentId'
														label='Department'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel=''
															placeholder='Select Department'
															onChange={(e: any) =>
																setDepartmentId(e)
															}
															value={departmentId}
															list={departmentData.map(
																(data: any) => ({
																	value: data.departmentId,
																	label: data.departmentName,
																}),
															)}
															isValid={joiningForm.isValid}
															isTouched={
																joiningForm.touched.departmentId
															}
															invalidFeedback={
																joiningForm.errors.departmentId
															}
														/>
													</FormGroup>
												</div>

												<div className='col-lg-4'>
													<FormGroup
														id='approvalNo'
														label='Approval No'
														isFloating>
														<Input
															type='text'
															placeholder='Approval No'
															onChange={joiningForm.handleChange}
															value={joiningForm.values.approvalNo}
															onBlur={joiningForm.handleBlur}
															isValid={joiningForm.isValid}
															isTouched={
																joiningForm.touched.approvalNo
															}
															invalidFeedback={
																joiningForm.errors.approvalNo
															}
														/>
													</FormGroup>
												</div>
											</>
										) : null}

										{/* {isJoiningDetails != 'isTeachingStaff' && isJoiningDetails != 'isNonTeachingStaff' ?
                                            <div className="col-lg-8" /> : null} */}

										{isJoiningDetails == 'isDriver' ? (
											<>
												<div className='col-lg-4'>
													<FormGroup
														id='experienceInYear'
														label='Experience in year'
														isFloating>
														<Input
															type='text'
															placeholder='Enter Experience in year'
															onChange={joiningForm.handleChange}
															value={
																joiningForm.values.experienceInYear
															}
														/>
													</FormGroup>
												</div>
												<div className='col-lg-4'>
													<FormGroup id='badge' label='Badge' isFloating>
														<Input
															type='text'
															placeholder='Enter Badge'
															onChange={joiningForm.handleChange}
															value={joiningForm.values.badge}
														/>
													</FormGroup>
												</div>
												<div className='col-lg-4'>
													<FormGroup
														id='badgeExpiryDate'
														label='Badge Expiry date'
														isFloating>
														<Input
															type='date'
															placeholder='Enter Badge Expiry date'
															onChange={joiningForm.handleChange}
															value={
																joiningForm.values.badgeExpiryDate
															}
														/>
													</FormGroup>
												</div>
												<div className='col-lg-4'>
													<FormGroup
														id='licenseNumber'
														label='License Number'
														isFloating>
														<Input
															type='text'
															placeholder='Enter License Number'
															onChange={joiningForm.handleChange}
															value={joiningForm.values.licenseNumber}
															onBlur={joiningForm.handleBlur}
															isValid={joiningForm.isValid}
															isTouched={
																joiningForm.touched.licenseNumber
															}
															invalidFeedback={
																joiningForm.errors.licenseNumber
															}
														/>
													</FormGroup>
												</div>
												<div className='col-lg-4'>
													<FormGroup
														id='licenseExpiryDate'
														label='License Expiry date'
														isFloating>
														<Input
															type='date'
															placeholder='Enter License Expiry date'
															onChange={joiningForm.handleChange}
															value={
																joiningForm.values.licenseExpiryDate
															}
														/>
													</FormGroup>
												</div>
											</>
										) : null}

										<div className='col-lg-4'>
											<FormGroup
												id='dateOfJoining'
												label='Date of Joining'
												isFloating>
												<Input
													type='date'
													placeholder='Enter Date of Joining'
													onChange={joiningForm.handleChange}
													value={joiningForm.values.dateOfJoining}
													onBlur={joiningForm.handleBlur}
													isValid={joiningForm.isValid}
													isTouched={joiningForm.touched.dateOfJoining}
													invalidFeedback={
														joiningForm.errors.dateOfJoining
													}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='appointmentLt'
												label='Appointment Lt'
												isFloating>
												<Input
													type='date'
													placeholder='Enter Appointment Lt'
													onChange={joiningForm.handleChange}
													value={joiningForm.values.appointmentLt}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup
												id='expertise'
												label='Expertise(Area of Specialization)'
												isFloating>
												<Input
													type='text'
													placeholder='Enter Expertise'
													onChange={joiningForm.handleChange}
													value={joiningForm.values.expertise}
												/>
											</FormGroup>
										</div>
										<div className='col-lg-4'>
											<FormGroup id='jobType' label='Job Type' isFloating>
												<Select
													ariaLabel=''
													placeholder='Select Job Type'
													onChange={joiningForm.handleChange}
													value={joiningForm.values.jobType}>
													<option value='Permanent'>Permanent</option>
													<option value='Temporary'>Temporary</option>
												</Select>
											</FormGroup>
										</div>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
										className='mt-4'>
										<Button
											color='primary'
											onClick={handlePrevious}
											type='submit'
											isLink>
											Previous
										</Button>
										<Button color='info' type='submit'>
											Next
										</Button>
									</div>
								</form>
							</Card>
						)}

						{step == 4 && (
							<Card shadow='none'>
								<form noValidate onSubmit={salaryInfoForm.handleSubmit}>
									<div className='d-grid gap-4'>
										<div className='row'>
											<div className='col-3'>
												<FormGroup
													id='salaryTemplateId'
													isFloating
													label='Salary Templates'>
													<SearchableSelect
														isFloating
														ariaLabel=''
														placeholder='Select Template'
														onChange={(e: any) =>
															selectSalaryTemplate(e)
														}
														value={salaryTemplateId}
														onBlur={salaryInfoForm.handleBlur}
														isValid={salaryInfoForm.isValid}
														invalidFeedback={
															salaryInfoForm.errors.salaryTemplateId
														}
														isTouched={
															salaryInfoForm.touched.salaryTemplateId
														}
														list={salaryTemplateData.map(
															(data: any) => ({
																value: data.salaryTemplateId,
																label: data.templateName,
															}),
														)}
													/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='annualCTC'
													isFloating
													label='Annual CTC (Per Year)'>
													<Input
														aria-placeholder=''
														placeholder='Enter Annual CTC'
														onInput={(e: any) =>
															multiply(Number(e.target.value))
														}
														value={annualCTC}
														onBlur={salaryInfoForm.handleBlur}
														isValid={salaryInfoForm.isValid}
														invalidFeedback={
															salaryInfoForm.errors.annualCTC
														}
														isTouched={salaryInfoForm.touched.annualCTC}
														onKeyDown={(e: any) => onlyAllowNumber(e)}
													/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup id='isConsiderForEpfContribution' label='Is Consider For Epf' isFloating>
													<Select
														ariaLabel=''
														placeholder='Select Consider For Epf'
														onChange={salaryInfoForm.handleChange}
														value={salaryInfoForm.values.isConsiderForEpfContribution}
														onBlur={salaryInfoForm.handleBlur}
														isValid={salaryInfoForm.isValid}
														invalidFeedback={
															salaryInfoForm.errors.isConsiderForEpfContribution
														}
														isTouched={salaryInfoForm.touched.isConsiderForEpfContribution}>
														<option value='1'>Yes</option>
														<option value='0'>No</option>
													</Select>
												</FormGroup>
											</div>
											<div className='col-3 mt-2'>
												<Button
													icon='ArrowDownward'
													color='primary'
													isDisable={salaryTemplateId?.value != undefined && annualCTC > 0 ? false : true}
													onClick={() =>
														getStaffSalaryList(
															salaryTemplateId?.value,
															annualCTC,
														)
													}>
													View Salary Details
												</Button>
											</div>

											{dataStatus ?
												<div className='col-12 mt-4'>
													<TableLoader />
												</div> :

												<>{staffSalaryDetails != '' ?
													<div className='col-12 mt-4'>
														<table className='table table-modern table-hover text-nowrap'>
															<thead>
																<tr className='table-success text-decoration-underline mt-4'>
																	<th>Salary Components</th>
																	<th>Calculation Type</th>
																	<th>Monthly Amount</th>
																	<th>Annual Amount</th>
																</tr>
															</thead>
															<tbody>
																{staffSalaryDetails?.map(
																	(i: any) => (
																		<tr
																			className='text-start'
																			key={i.salaryTemplateId}>
																			<td>{i.earningName}</td>
																			<td>{i.labelValue}</td>
																			<td>{i.monthlyAmount}</td>
																			<td>{i.annualAmount}</td>
																		</tr>
																	),
																)}
																{/* <tr>
																	<td>
																		Fixed Allowance <br />
																		Monthly CTC - Sum of all other
																		components
																	</td>
																	<td className='text-start'>
																		<div className='mt-2 ms-1'>
																			Fixed amount
																		</div>
																	</td>
																	<td className='text-start'>
																		<div className='mt-2 ms-1'>
																			0.00
																		</div>
																	</td>
																	<td className='text-start'>
																		<div className='mt-2 ms-1'>
																			0.00
																		</div>
																	</td>
																</tr> */}
																<tr>
																	<td colSpan={2}>
																		<strong>Cost to Company</strong>
																	</td>
																	<td className='text-start'>
																		<strong className='mt-2 ms-1'>
																			₹ {totalMonthlyAmount}
																		</strong>
																	</td>
																	<td className='text-start'>
																		<strong className='mt-2 ms-1'>
																			₹ {totalAnnualAmount}
																		</strong>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
													: null}
												</>
											}
										</div>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
										className='mt-4'>
										<Button
											color='primary'
											onClick={handlePrevious}
											type='submit'
											isLink>
											Previous
										</Button>
										<Button color="info" type="submit" >Submit</Button>
									</div>
								</form>
							</Card>
						)}
					</CardBody>
				</Card>
			</Page >
			<AlertService
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				alertStatus={alertStatus}
				isNavigate={isNavigate}
			/>
		</PageWrapper >
	);
}
export default CreateStaff;
