import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import useDarkMode from '../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import { useReactToPrint } from 'react-to-print';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import AuthContext from '../../contexts/authContext';
import {
	getColumnsForDataTable,
	onlyAllowNumber,
	updateColumnsForDataTable,
	updateFilter,
} from '../../services/common.service';
import { addEarningDetails, deleteEarningDetails, getEarningDetailsList, getEarningTypeList, updateEarningDetails } from '../../services/salary.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';
import { TableLoader, showLoader } from '../../services/loader.services';
import SearchableSelect from '../../common/components/SearchableSelect';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../bootstrap/OffCanvas';
import AlertService from '../../services/AlertService';
import DeleteComponents from '../../common/components/DeleteComponents';
import InputGroup, { InputGroupText } from '../bootstrap/forms/InputGroup';

const salaryComponents = () => {

	useEffect(() => {
		getEarningDetails();
		getEarningType();
	}, []);

	const earningForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			earningTypeId: '',
			earningName: '',
			nameInPaySlip: '',
			payType: '',
			fromCTc: '',
			fromBasicSalary: '',
			amount: '',
			percentage: '',
			isPartInEmployeeSalary: '',
			isTaxableEarning: '',
			isCalculateOnProRataBasis: '',
			isConsiderForEpfContribution: '',
			isConsiderForEsiContribution: '',
			isConsiderForEpfContributionAmount: '',
			isConsiderForEpfContributionPercentage: '',
			isShownInPaySlip: '',
		},
		validate: (values) => {
			const errors: {
				earningTypeId?: string;
				earningName?: string;
				nameInPaySlip?: string;
				calculationTypeId?: string;
				isPartInEmployeeSalary?: string;
				isTaxableEarning?: string;
				isCalculateOnProRataBasis?: string;
				isConsiderForEpfContribution?: string;
				isConsiderForEsiContribution?: string;
				isShownInPaySlip?: string;
				amount?: string;
				percentage?: string;
				isConsiderForEpfContributionAmount?: string;
				isConsiderForEpfContributionPercentage?: string;
			} = {};
			if (!earningTypeId) {
				errors.earningTypeId = 'Required';
			}
			if (!values.earningName) {
				errors.earningName = 'Required';
			}
			if (!values.nameInPaySlip) {
				errors.nameInPaySlip = 'Required';
			}
			if (!calculationTypeId) {
				errors.calculationTypeId = 'Required';
			}
			if (calculationTypeId == "1") {
				if (!values.amount) {
					errors.amount = 'Required';
				}
			} else if (calculationTypeId == "2") {
				if (!values.percentage) {
					errors.percentage = 'Required';
				}
			}
			if (isEpfContributionType == "1") {
				if (!values.isConsiderForEpfContributionAmount) {
					errors.isConsiderForEpfContributionAmount = 'Required';
				}
			} else if (isEpfContributionType == "2") {
				if (!values.isConsiderForEpfContributionPercentage) {
					errors.isConsiderForEpfContributionPercentage = 'Required';
				}
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => { AddEarningSubmit() },
	});

	const earningEditForm = useFormik({
		initialValues: {
			earningTypeId: '',
			earningDetailsId: '',
			earningName: '',
			nameInPaySlip: '',
			payType: '',
			fromCTc: '',
			fromBasicSalary: '',
			amount: '',
			percentage: '',
			isPartInEmployeeSalary: 0,
			isTaxableEarning: 0,
			isCalculateOnProRataBasis: 0,
			isConsiderForEpfContribution: 0,
			isConsiderForEpfContributionAmount: '',
			isConsiderForEpfContributionPercentage: '',
			isConsiderForEsiContribution: 0,
			isShownInPaySlip: 0,
		},
		validate: (values) => {
			const errors: {
				earningTypeId?: string;
				earningName?: string;
				nameInPaySlip?: string;
				calculationTypeId?: string;
				isPartInEmployeeSalary?: string;
				isTaxableEarning?: string;
				isCalculateOnProRataBasis?: string;
				isConsiderForEpfContribution?: string;
				isConsiderForEsiContribution?: string;
				isShownInPaySlip?: string;
				isConsiderForEpfContributionAmount?: string;
				isConsiderForEpfContributionPercentage?: string;
			} = {};
			if (!earningTypeId) {
				errors.earningTypeId = 'Required';
			}
			if (!values.earningName) {
				errors.earningName = 'Required';
			}
			if (!values.nameInPaySlip) {
				errors.nameInPaySlip = 'Required';
			}
			if (!calculationTypeId) {
				errors.calculationTypeId = 'Required';
			}
			if (isEpfContributionType == "1") {
				if (!values.isConsiderForEpfContributionAmount) {
					errors.isConsiderForEpfContributionAmount = 'Required';
				}
			} else if (isEpfContributionType == "2") {
				if (!values.isConsiderForEpfContributionPercentage) {
					errors.isConsiderForEpfContributionPercentage = 'Required';
				}
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => { updateEarningSubmit() },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		validateOnChange: false,
		onSubmit: () => { },
	});

	const TABS = {
		Earning: 'Earning',
	};

	const [activeTab, setActiveTab] = useState(TABS.Earning);
	const navigate = useNavigate();
	const { userAccountId, userTypeId, organisationDetailsId } = useContext(AuthContext);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [earningData, setEarningData] = useState<any>([]);
	const [earningTypeData, setEarningTypeData] = useState<any>([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(earningData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [selectedEarnings, setSelectedEarnings] = useState<any>([]);
	const [addEarningDetailsOffCanvas, setAddEarningDetailsOffCanvas] = useState(false);
	const [editEarningDetailsOffCanvas, setEditEarningDetailsOffCanvas] = useState(false);

	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [earningTypeId, setEarningTypeId] = useState<any>('');
	const [calculationTypeId, setCalculationTypeId] = useState('1');
	const [isEpfContribution, setEpfContribution] = useState(false);
	const [isEpfContributionType, setEpfContributionType] = useState('');
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [isOpen, setIsOpen] = useState(false);
	const [description, setDescription] = useState<any>([]);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedEarning = i
			setEarningTypeId({
				value: selectedEarning.earningTypeId,
				label: selectedEarning.typeName,
			});
			setEpfContribution(selectedEarning.isConsiderForEpfContribution == 'No' ? false : true);
			setEpfContributionType(selectedEarning.isConsiderForEpfContributionTypeId);
			setCalculationTypeId(selectedEarning.calculationTypeId);

			let descriptionData = earningTypeData.filter((item: any) => item.earningTypeId == selectedEarning.earningTypeId)
			setDescription(descriptionData != "" ? descriptionData[0].description : "")

			earningEditForm.setValues({
				earningTypeId: selectedEarning.earningTypeId,
				earningDetailsId: selectedEarning.earningDetailsId,
				earningName: selectedEarning.earningName,
				nameInPaySlip: selectedEarning.nameInPaySlip,
				payType: selectedEarning.payType,
				fromCTc: selectedEarning.fromCTc,
				fromBasicSalary: selectedEarning.fromBasicSalary,
				amount: selectedEarning.amount,
				percentage: selectedEarning.percentage,
				isPartInEmployeeSalary: selectedEarning.isPartInEmployeeSalary,
				isTaxableEarning: selectedEarning.isTaxableEarning,
				isCalculateOnProRataBasis: selectedEarning.isCalculateOnProRataBasis,
				isConsiderForEpfContribution: selectedEarning.isConsiderForEpfContribution,
				isConsiderForEpfContributionAmount: selectedEarning.isConsiderForEpfContributionAmount,
				isConsiderForEpfContributionPercentage: selectedEarning.isConsiderForEpfContributionPercentage,
				isConsiderForEsiContribution: selectedEarning.isConsiderForEsiContribution,
				isShownInPaySlip: selectedEarning.isShownInPaySlip,
			});
			setEditEarningDetailsOffCanvas(true)


		}
	}

	function setAddEarningDetails() {

		return {
			earningTypeId: earningTypeId?.value == undefined ? 0 : earningTypeId?.value,
			earningName: earningForm.values.earningName,
			nameInPaySlip: earningForm.values.nameInPaySlip,
			payType: '1',
			calculationType: calculationTypeId,
			fromCTc: earningTypeId?.value == '1' && calculationTypeId == '1' ? 1 : 0,
			fromBasicSalary: earningTypeId?.value != '1' && calculationTypeId == '2' ? 1 : 0,
			amount: earningForm.values.amount != '' ? earningForm.values.amount : 0,
			percentage: earningForm.values.percentage != '' ? earningForm.values.percentage : 0,
			isPartInEmployeeSalary: earningForm.values.isPartInEmployeeSalary ? 1 : 0,
			isTaxableEarning: earningForm.values.isTaxableEarning ? 1 : 0,
			isCalculateOnProRataBasis: earningForm.values.isCalculateOnProRataBasis ? 1 : 0,
			isConsiderForEpfContribution: isEpfContribution == true ? 1 : 0,
			isConsiderForEpfContributionType: isEpfContributionType,
			isConsiderForEpfContributionAmount: earningForm.values.isConsiderForEpfContributionAmount != '' ? earningForm.values.isConsiderForEpfContributionAmount : 0,
			isConsiderForEpfContributionPercentage: earningForm.values.isConsiderForEpfContributionPercentage != '' ? earningForm.values.isConsiderForEpfContributionPercentage : 0,
			isConsiderForEsiContribution: earningForm.values.isConsiderForEsiContribution ? 1 : 0,
			isShownInPaySlip: earningForm.values.isShownInPaySlip ? 1 : 0,
			organisationDetailsId: organisationDetailsId,
			createdBy: userAccountId,
			licenseKey: getLicenseKey
		}
	}

	function setUpdateEarningDetails() {

		return ({
			earningTypeId: earningTypeId?.value == '' ? 0 : earningTypeId?.value,
			earningDetailsId: earningEditForm.values.earningDetailsId,
			earningName: earningEditForm.values.earningName,
			nameInPaySlip: earningEditForm.values.nameInPaySlip,
			payType: '1',
			calculationType: calculationTypeId,
			fromCTc: earningTypeId?.value == '1' && calculationTypeId == '1' ? 1 : 0,
			fromBasicSalary: earningTypeId?.value != '1' && calculationTypeId == '2' ? 1 : 0,
			amount: earningEditForm.values.amount != '' ? earningEditForm.values.amount : 0,
			percentage: earningEditForm.values.percentage != '' ? earningEditForm.values.percentage : 0,
			isPartInEmployeeSalary: earningEditForm.values.isPartInEmployeeSalary ? 1 : 0,
			isTaxableEarning: earningEditForm.values.isTaxableEarning ? 1 : 0,
			isCalculateOnProRataBasis: earningEditForm.values.isCalculateOnProRataBasis ? 1 : 0,
			isConsiderForEpfContribution: isEpfContribution == true ? 1 : 0,
			isConsiderForEpfContributionType: isEpfContributionType,
			isConsiderForEpfContributionAmount: earningEditForm.values.isConsiderForEpfContributionAmount,
			isConsiderForEpfContributionPercentage: earningEditForm.values.isConsiderForEpfContributionPercentage,
			isConsiderForEsiContribution: earningEditForm.values.isConsiderForEsiContribution ? 1 : 0,
			isShownInPaySlip: earningEditForm.values.isShownInPaySlip ? 1 : 0,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey
		})
	}

	function setDeleteEarning() {

		return ({
			earningDetailsId: earningEditForm.values.earningDetailsId,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey
		})
	}

	function getEarningDetails() {
		getEarningDetailsList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.earningDetailsList;
					if (data != undefined) {
						getColumnsForTable('getEarningDetailsList', 'get');
						setEarningData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					getColumnsForTable('getEarningDetailsList', 'get');
					setDataStatus(true);
					setEarningData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getEarningType() {
		getEarningTypeList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.earningTypeList;
					if (data != undefined) {
						setEarningTypeData(data);
					}
					else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setEarningTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function AddEarningSubmit() {
		showLoader(true)
		if (earningForm.isValid) {
			let earningsData = setAddEarningDetails()
			addEarningDetails(earningsData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getEarningDetails();
						closeAndResetForm();
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "warning" });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
				}, (error) => {
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (earningForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
			setIsOpen(true);
		}
	}

	function updateEarningSubmit() {
		showLoader(true);
		if (earningEditForm.isValid) {
			let earningPostData = setUpdateEarningDetails()
			updateEarningDetails(earningPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getEarningDetails()
						closeAndResetForm()
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "warning" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)

		} else if (earningForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
			setIsOpen(true);
		}

	}

	function deleteEarningSubmit() {
		showLoader(true);
		let earningsData = setDeleteEarning()
		deleteEarningDetails(earningsData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: "success" });
					setIsOpen(true);
					getEarningDetails();
					closeAndResetForm()

				}
				else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: "warning" });
					setIsOpen(true);
				}
				else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: "error" });
					setIsOpen(true);
				}
			}
			, (error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: "error" });
				setIsOpen(true);
			}
		)
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getEarningDetailsList', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	const [doChecked, setDoChecked] = useState(false);

	function doCheck() {
		setDoChecked(!doChecked)
	}

	function closeAndResetForm() {
		setAddEarningDetailsOffCanvas(false);
		setEditEarningDetailsOffCanvas(false);
		setIsDeleteOpen(false)
		resetForm();
	}

	function openEarningOffCanvas() {
		getEarningType();
		resetForm();
		setAddEarningDetailsOffCanvas(true);
	}

	function selectCalculationType(typeId: any, formName: any) {
		setCalculationTypeId(typeId)
		if (formName == "earningForm") {
			earningForm.setValues({
				earningTypeId: earningForm.values.earningTypeId,
				earningName: earningForm.values.earningName,
				nameInPaySlip: earningForm.values.nameInPaySlip,
				payType: earningForm.values.payType,
				fromCTc: earningForm.values.fromCTc,
				fromBasicSalary: earningForm.values.fromBasicSalary,
				amount: '',
				percentage: '',
				isPartInEmployeeSalary: earningForm.values.isPartInEmployeeSalary,
				isTaxableEarning: earningForm.values.isTaxableEarning,
				isCalculateOnProRataBasis: earningForm.values.isCalculateOnProRataBasis,
				isConsiderForEpfContribution: earningForm.values.isConsiderForEpfContribution,
				isConsiderForEpfContributionAmount: earningForm.values.isConsiderForEpfContributionAmount,
				isConsiderForEpfContributionPercentage: earningForm.values.isConsiderForEpfContributionPercentage,
				isConsiderForEsiContribution: earningForm.values.isConsiderForEsiContribution,
				isShownInPaySlip: earningForm.values.isShownInPaySlip,
			});
		} else {
			earningEditForm.setValues({
				earningTypeId: earningEditForm.values.earningTypeId,
				earningDetailsId: earningEditForm.values.earningDetailsId,
				earningName: earningEditForm.values.earningName,
				nameInPaySlip: earningEditForm.values.nameInPaySlip,
				payType: earningEditForm.values.payType,
				fromCTc: earningEditForm.values.fromCTc,
				fromBasicSalary: earningEditForm.values.fromBasicSalary,
				amount: '',
				percentage: '',
				isPartInEmployeeSalary: earningEditForm.values.isPartInEmployeeSalary,
				isTaxableEarning: earningEditForm.values.isTaxableEarning,
				isCalculateOnProRataBasis: earningEditForm.values.isCalculateOnProRataBasis,
				isConsiderForEpfContribution: earningEditForm.values.isConsiderForEpfContribution,
				isConsiderForEpfContributionAmount: earningEditForm.values.isConsiderForEpfContributionAmount,
				isConsiderForEpfContributionPercentage: earningEditForm.values.isConsiderForEpfContributionPercentage,
				isConsiderForEsiContribution: earningEditForm.values.isConsiderForEsiContribution,
				isShownInPaySlip: earningEditForm.values.isShownInPaySlip,
			});
		}
	}

	function selectEpfContributionType(typeId: any, formName: any) {
		setEpfContributionType(typeId)
		if (formName == "earningForm") {
			earningForm.setValues({
				earningTypeId: earningForm.values.earningTypeId,
				earningName: earningForm.values.earningName,
				nameInPaySlip: earningForm.values.nameInPaySlip,
				payType: earningForm.values.payType,
				fromCTc: earningForm.values.fromCTc,
				fromBasicSalary: earningForm.values.fromBasicSalary,
				amount: earningForm.values.amount,
				percentage: earningForm.values.percentage,
				isPartInEmployeeSalary: earningForm.values.isPartInEmployeeSalary,
				isTaxableEarning: earningForm.values.isTaxableEarning,
				isCalculateOnProRataBasis: earningForm.values.isCalculateOnProRataBasis,
				isConsiderForEpfContribution: earningForm.values.isConsiderForEpfContribution,
				isConsiderForEpfContributionAmount: '',
				isConsiderForEpfContributionPercentage: '',
				isConsiderForEsiContribution: earningForm.values.isConsiderForEsiContribution,
				isShownInPaySlip: earningForm.values.isShownInPaySlip,
			});
		} else {
			earningEditForm.setValues({
				earningTypeId: earningEditForm.values.earningTypeId,
				earningDetailsId: earningEditForm.values.earningDetailsId,
				earningName: earningEditForm.values.earningName,
				nameInPaySlip: earningEditForm.values.nameInPaySlip,
				payType: earningEditForm.values.payType,
				fromCTc: earningEditForm.values.fromCTc,
				fromBasicSalary: earningEditForm.values.fromBasicSalary,
				amount: earningEditForm.values.amount,
				percentage: earningEditForm.values.percentage,
				isPartInEmployeeSalary: earningEditForm.values.isPartInEmployeeSalary,
				isTaxableEarning: earningEditForm.values.isTaxableEarning,
				isCalculateOnProRataBasis: earningEditForm.values.isCalculateOnProRataBasis,
				isConsiderForEpfContribution: earningEditForm.values.isConsiderForEpfContribution,
				isConsiderForEpfContributionAmount: '',
				isConsiderForEpfContributionPercentage: '',
				isConsiderForEsiContribution: earningEditForm.values.isConsiderForEsiContribution,
				isShownInPaySlip: earningEditForm.values.isShownInPaySlip,
			});
		}
	}

	function resetForm() {
		earningForm.resetForm();
		setEarningTypeId('');
		setDescription('');
		setCalculationTypeId('1');
		setEpfContributionType('0');
		setEpfContribution(false);
	}

	const selectEaring = (e: any) => {
		let earningTypeId = e
		setEarningTypeId(earningTypeId)
		let descriptionData = earningTypeData.filter((item: any) => item.earningTypeId == earningTypeId?.value)
		setDescription(descriptionData != "" ? descriptionData[0].description : "")
	}

	//   Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Salary Components'>
			<Page>
				<Card stretch data-tour='list' ref={componentRef} id='pdf'>
					<CardHeader borderSize={1}>
						<CardLabel icon='List' iconColor='primary'>
							<CardTitle tag='div' className='h5'>
								Salary Components
							</CardTitle>

						</CardLabel>
						<CardActions className='d-print-none'>
							<ButtonGroup color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>

							<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
								<DropdownToggle>
									<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<DropdownItem>
										<div className='container py-2'>
											<form className='row g-3'>
												<div className='col-12'>
													<FormGroup>
														<h6>Select All</h6>
														<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getEarningDetailsList', 'post')} checked={isChecked} ariaLabel='Available status' />
													</FormGroup>
												</div>
												<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
													<FormGroup>
														<h6>Columns</h6>
														<ChecksGroup>
															{allColumnsData.map((i: any) => (
																<Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
															))}
														</ChecksGroup>
													</FormGroup>
												</div>
											</form>
										</div>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Dropdown isButtonGroup>
								<DropdownToggle>
									<Button isLight icon='CloudDownload' color='primary'>
										{' '}
										Export
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='CloudDownload'
											onClick={() =>
												downloadExcel(
													'EaringDetails',
													earningData,
													columnVisibilityData,
												)
											}>
											{' '}
											Excel{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='PictureAsPdf'
											onClick={() =>
												convertJsonToPdf(
													earningData,
													columnVisibilityData,
													'EaringDetails',
												)
											}>
											{' '}
											PDF
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='CloudDownload'
											onClick={() =>
												downloadFile(
													'EaringDetails',
													earningData,
													columnVisibilityData,
												)
											}>
											{' '}
											CSV{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='Print'
											onClick={handlePrint}>
											{' '}
											Print{' '}
										</Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Button
								color='primary'
								icon='Add'
								isLight
								onClick={openEarningOffCanvas}
							>
								Add New
							</Button>
						</CardActions>
					</CardHeader>
					{TABS.Earning === activeTab && (
						<>
							<CardBody className='table-responsive' isScrollable>
								{!dataStatus ? (
									<TableLoader />
								) : (
									<table className='table table-modern table-hover text-nowrap'>
										<thead>
											<tr>
												<th
													scope='col'
													onClick={() => requestSort('sno')}
													className='cursor-pointer text-decoration-underline'>
													S.No{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('sno')}
														icon='FilterList'
													/>
												</th>
												{columnVisibilityData.map((column: any) => (
													<th
														key={column.keyName}
														scope='col'
														onClick={() => requestSort(column.keyName)}
														className='cursor-pointer text-decoration-underline'>
														{column.columnName}
														<Icon
															size='lg'
															className={getClassNamesFor(
																column.keyName,
															)}
															icon='FilterList'
														/>
													</th>
												))}
												<th scope='col' className='d-print-none'>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{filteredData != '' ? (
												<>
													{filteredData.map((rowData: any) => (
														<tr key={rowData.earningDetailsId}>
															<td>{rowData.sno}</td>
															{columnVisibilityData.map(
																(column: any) =>
																	column.isDisplay &&
																		rowData[column.keyName] ? (
																		<td
																			key={`${rowData.earningDetailsId}-${column.keyName}`}>
																			{
																				rowData[
																				column.keyName
																				]
																			}
																		</td>
																	) : (
																		<td
																			key={`empty-${column.keyName}`}
																		/>
																	),
															)}
															<td className='d-print-none'>
																{' '}
																<Button
																	onClick={() => {
																		onActivate(rowData);
																	}}
																	isOutline={!darkModeStatus}
																	color='dark'
																	className={classNames(
																		'text-nowrap',
																		{
																			'border-light':
																				!darkModeStatus,
																		},
																	)}
																	isLight icon='Edit'>
																</Button>
															</td>
														</tr>
													))}
												</>
											) : (
												<NoDataMsg
													columnsCount={columnVisibilityData.length + 2}
													msg={noDataMsg}
												/>
											)}
										</tbody>
									</table>
								)}
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</>
					)}
				</Card>
			</Page>

			<OffCanvas
				setOpen={setAddEarningDetailsOffCanvas}
				isOpen={addEarningDetailsOffCanvas}
				titleId='addEarningDetailsOffCanvas'
				placement='end'
				isBodyScroll
				tag='form'
				noValidate
				onSubmit={earningForm.handleSubmit}>
				<OffCanvasHeader setOpen={setAddEarningDetailsOffCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='addEarningDetailsOffCanvas'>
						Add Earning Details
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className="row g-4">
						<div className='col-12'>
							<FormGroup id='earningTypeId' label='Earning Type' >
								<SearchableSelect
									placeholder='Earning Type'
									ariaLabel='Earning Type'
									list={earningTypeData.map((data: any) => (
										{ value: data.earningTypeId, label: data.typeName }
									))}
									onChange={selectEaring}
									value={earningTypeId}
									onBlur={earningForm.handleBlur}
									isValid={earningForm.isValid}
									isTouched={earningForm.touched.earningTypeId}
									invalidFeedback={earningForm.errors.earningTypeId}
								/>
							</FormGroup>
						</div>

						{description != "" ?
							<div className='col-12'>
								<CardLabel>
									<CardSubTitle className='mt-1 text-info'><Icon icon='LabelImportant' color='danger' size="lg" />  {description}</CardSubTitle>
								</CardLabel>
							</div> : null}
						<div className='col-12'>
							<FormGroup id='earningName' label='Earning Name'>
								<Input
									placeholder="Earning Name"
									onChange={earningForm.handleChange}
									value={earningForm.values.earningName}
									onBlur={earningForm.handleBlur}
									isValid={earningForm.isValid}
									isTouched={earningForm.touched.earningName}
									invalidFeedback={earningForm.errors.earningName}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='nameInPaySlip' label='Name in Payslip' >
								<Input
									placeholder="Name In Payslip"
									onChange={earningForm.handleChange}
									value={earningForm.values.nameInPaySlip}
									onBlur={earningForm.handleBlur}
									isValid={earningForm.isValid}
									isTouched={earningForm.touched.nameInPaySlip}
									invalidFeedback={earningForm.errors.nameInPaySlip}
								/>
							</FormGroup>
						</div>

						<div className='col-12'>
							<div className='fw-bold'> Calculation Type : </div>
						</div>
						<div className='col-5'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault1'
								label='Flat Amount'
								value='1'
								onChange={() => selectCalculationType("1", "earningForm")}
								checked={calculationTypeId}
							/>
						</div>
						<div className='col-7'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault2'
								label={earningTypeId?.value == 1 ? 'Percentage of CTC' : earningTypeId == "" ?'Percentage of CTC' : 'Percentage of Basic Salary'}
								value='2'
								onChange={() => selectCalculationType("2", "earningForm")}
								checked={calculationTypeId}
							/>
						</div>
						{calculationTypeId == '1' ?
							<div className='col-12'>
								<FormGroup id='amount' label='Enter Amount' >
									<Input
										placeholder="Amount"
										onChange={earningForm.handleChange}
										value={earningForm.values.amount}
										onBlur={earningForm.handleBlur}
										isValid={earningForm.isValid}
										isTouched={earningForm.touched.amount}
										invalidFeedback={earningForm.errors.amount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div> :
							null}
						{calculationTypeId == '2' ?
							<div className='col-12'>
								<FormGroup id='percentage' label='Enter Percentage' >
									<InputGroup>
										<Input
											id='percentage'
											placeholder="percentage"
											onChange={earningForm.handleChange}
											value={earningForm.values.percentage}
											onBlur={earningForm.handleBlur}
											isValid={earningForm.isValid}
											isTouched={earningForm.touched.percentage}
											invalidFeedback={earningForm.errors.percentage}
											onKeyDown={(e: any) => onlyAllowNumber(e)}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>
								</FormGroup>
							</div> : null}

						<div className='col-12'>
							<div className='fw-bold'>
								<Checks
									className='mt-1'
									type='checkbox'
									label='Consider for EPF Contribution'
									onChange={() => { setEpfContribution(!isEpfContribution); selectEpfContributionType('0', "earningForm") }}
									checked={isEpfContribution}
								/>
							</div>
						</div>
						<div className="row">
							<div className='col-1 mt-1'></div>
							<div className='col-11 mt-1'>
								<Checks disabled={isEpfContribution == true ? false : true}
									type='radio'
									name='isEpfContributionType'
									id='isEpfContributionDetails1'
									label='Flat Amount'
									value='1'
									onChange={() => selectEpfContributionType('1', "earningForm")}
									checked={isEpfContributionType}
								/>

								<Checks disabled={isEpfContribution == true ? false : true}
									type='radio'
									name='isEpfContributionType'
									id='isEpfContributionDetails2'
									label={'Percentage'}
									value='2'
									onChange={() => selectEpfContributionType('2', "earningForm")}
									checked={isEpfContributionType}
								/>
							</div>
						</div>
						{isEpfContributionType == '1' ?
							<div className='col-12'>
								<FormGroup id='isConsiderForEpfContributionAmount' label='Enter Amount' >
									<Input
										placeholder="Amount"
										onChange={earningForm.handleChange}
										value={earningForm.values.isConsiderForEpfContributionAmount}
										onBlur={earningForm.handleBlur}
										isValid={earningForm.isValid}
										isTouched={earningForm.touched.isConsiderForEpfContributionAmount}
										invalidFeedback={earningForm.errors.isConsiderForEpfContributionAmount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div> :
							null}
						{isEpfContributionType == '2' ?
							<div className='col-12'>
								<FormGroup id='isConsiderForEpfContributionPercentage' label='Enter Percentage' >
									<InputGroup>
										<Input
											id='isConsiderForEpfContributionPercentage'
											placeholder="percentage"
											onChange={earningForm.handleChange}
											value={earningForm.values.isConsiderForEpfContributionPercentage}
											onBlur={earningForm.handleBlur}
											isValid={earningForm.isValid}
											isTouched={earningForm.touched.isConsiderForEpfContributionPercentage}
											invalidFeedback={earningForm.errors.isConsiderForEpfContributionPercentage}
											onKeyDown={(e: any) => onlyAllowNumber(e)}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>
								</FormGroup>
							</div> : null}
						<div className='col-12'><br /><br /><br /></div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<Button
							color='info'
							icon='Save'
							className='w-100'
							type='submit'
							isDisable={!earningForm.isValid && !!earningForm.submitCount}>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={setEditEarningDetailsOffCanvas}
				isOpen={editEarningDetailsOffCanvas}
				titleId='editEarningDetailsOffCanvas'
				placement='end'
				isBodyScroll
				tag='form'
				noValidate
				onSubmit={earningEditForm.handleSubmit}>
				<OffCanvasHeader setOpen={setEditEarningDetailsOffCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='editAdvanceSalaryOffCanvas'>Edit Earning Details
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className="row g-4">
						<div className='col-12'>
							<FormGroup id='earningTypeId' label='Earning Type'>
								<SearchableSelect
									placeholder='Earning Type'
									ariaLabel='Earning Type'
									list={earningTypeData.map((data: any) => (
										{ value: data.earningTypeId, label: data.typeName }
									))}
									onChange={selectEaring}
									value={earningTypeId}
									onBlur={earningEditForm.handleBlur}
									isValid={earningEditForm.isValid}
									isTouched={earningEditForm.touched.earningTypeId}
									invalidFeedback={earningEditForm.errors.earningTypeId}
								/>
							</FormGroup>
						</div>
						{description != "" ?
							<div className='col-12'>
								<CardLabel>
									<CardSubTitle className='mt-1 text-info'><Icon icon='LabelImportant' color='danger' size="lg" />  {description}</CardSubTitle>
								</CardLabel>
							</div> : null}
						<div className='col-12'>
							<FormGroup id='earningName' label='Earning Name'>
								<Input
									placeholder="Earning Name"
									onChange={earningEditForm.handleChange}
									value={earningEditForm.values.earningName}
									onBlur={earningEditForm.handleBlur}
									isValid={earningEditForm.isValid}
									isTouched={earningEditForm.touched.earningName}
									invalidFeedback={earningEditForm.errors.earningName}
								/>
							</FormGroup>
						</div>

						<div className='col-12'>
							<FormGroup id='nameInPaySlip' label='Name In Payslip' >
								<Input
									placeholder="Name In Payslip"
									onChange={earningEditForm.handleChange}
									value={earningEditForm.values.nameInPaySlip}
									onBlur={earningEditForm.handleBlur}
									isValid={earningEditForm.isValid}
									isTouched={earningEditForm.touched.nameInPaySlip}
									invalidFeedback={earningEditForm.errors.nameInPaySlip}
								/>
							</FormGroup>
						</div>

						<div className='col-12'>
							<div className='fw-bold'> Calculation Type : </div>
						</div>
						<div className='col-6'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault1'
								label='Flat Amount'
								value='1'
								onChange={() => selectCalculationType("1", "earningEditForm")}
								checked={calculationTypeId}
							/>
						</div>
						<div className='col-6'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault2'
								label={earningTypeId?.value != 1 ? 'Percentage of CTC' : ' Percentage of Basic Salary'}
								value='2'
								onChange={() => selectCalculationType("2", "earningEditForm")}
								checked={calculationTypeId}
							/>
						</div>
						{calculationTypeId == '1' ?
							<div className='col-12'>
								<FormGroup id='amount' label='Enter Amount' >
									<Input
										placeholder="0"
										onChange={earningEditForm.handleChange}
										value={earningEditForm.values.amount}
										onBlur={earningEditForm.handleBlur}
										isValid={earningEditForm.isValid}
										isTouched={earningEditForm.touched.amount}
										invalidFeedback={earningEditForm.errors.amount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div> :
							null}
						{calculationTypeId == '2' ?
							<div className='col-12'>
								<FormGroup id='percentage' label='Enter Percentage' >
									<InputGroup>
										<Input
											placeholder="0"
											onChange={earningEditForm.handleChange}
											value={earningEditForm.values.percentage}
											onBlur={earningEditForm.handleBlur}
											isValid={earningEditForm.isValid}
											isTouched={earningEditForm.touched.percentage}
											invalidFeedback={earningEditForm.errors.percentage}
											onKeyDown={(e: any) => onlyAllowNumber(e)}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>

								</FormGroup>
							</div> : null
						}

						<div className='col-12'>
							<div className='fw-bold'>
								<Checks
									className='mt-1'
									type='checkbox'
									label='Consider for EPF Contribution'
									onChange={() => { setEpfContribution(!isEpfContribution); selectEpfContributionType('0', "earningEditForm") }}
									checked={isEpfContribution}
								/>
							</div>
						</div>

						<div className="row">
							<div className='col-1 mt-1'></div>
							<div className='col-11 mt-1'>
								<Checks disabled={isEpfContribution == true ? false : true}
									type='radio'
									name='isEpfContributionType'
									id='isEpfContributionType1'
									label='Flat Amount'
									value='1'
									onChange={() => selectEpfContributionType('1', "earningEditForm")}
									checked={isEpfContributionType}
								/>

								<Checks disabled={isEpfContribution == true ? false : true}
									type='radio'
									name='isEpfContributionType'
									id='isEpfContributionType2'
									label={'Percentage of CTC'}
									value='2'
									onChange={() => selectEpfContributionType('2', "earningEditForm")}
									checked={isEpfContributionType}
								/>
							</div>
						</div>
						{isEpfContributionType == '1' ?
							<div className='col-12'>
								<FormGroup id='isConsiderForEpfContributionAmount' label='Enter Amount' >
									<Input
										placeholder="0"
										onChange={earningEditForm.handleChange}
										value={earningEditForm.values.isConsiderForEpfContributionAmount}
										onBlur={earningEditForm.handleBlur}
										isValid={earningEditForm.isValid}
										isTouched={earningEditForm.touched.isConsiderForEpfContributionAmount}
										invalidFeedback={earningEditForm.errors.isConsiderForEpfContributionAmount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div> :
							null}
						{isEpfContributionType == '2' ?
							<div className='col-12'>
								<FormGroup id='isConsiderForEpfContributionPercentage' label='Enter Percentage' >
									<InputGroup>
										<Input
											id='isConsiderForEpfContributionPercentage'
											placeholder="0"
											onChange={earningEditForm.handleChange}
											value={earningEditForm.values.isConsiderForEpfContributionPercentage}
											onBlur={earningEditForm.handleBlur}
											isValid={earningEditForm.isValid}
											isTouched={earningEditForm.touched.isConsiderForEpfContributionPercentage}
											invalidFeedback={earningEditForm.errors.isConsiderForEpfContributionPercentage}
											onKeyDown={(e: any) => onlyAllowNumber(e)}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>

								</FormGroup>
							</div> : null
						}


						<div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
							<DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteEarningSubmit} />
						</div>
						<div className='col-md-12'><br /><br /><br /></div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<div className='col-6 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}
							>
								Delete
							</Button>
						</div>
						<div className='col-6 p-3'>
							<Button
								color='info'
								icon='Save'
								type='submit'
								className='w-100'
								isDisable={!earningEditForm.isValid && !!earningEditForm.submitCount}
							>
								Update
							</Button>
						</div>
					</div>
				</div>
			</OffCanvas>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

		</PageWrapper>
	);
};
export default salaryComponents;
