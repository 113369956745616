import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getStaffListForPayRunReport = (year: any, month: any, paymentStatusId: any, paymentTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListForPayRunReport/${getLicenseKey}/${year}/${month}/${paymentStatusId}/${paymentTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListForPayRunReport - " + error.message)
            console.log('error caught in service : getStaffListForPayRunReport')
        },
    );