import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Collapse from '../bootstrap/Collapse';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import Button, { ButtonGroup } from '../bootstrap/Button';
import useSortableData from '../../hooks/useSortableData';
import Icon from '../icon/Icon';
import { toasts } from '../../services/toast.service';
import { dataPagination } from '../PaginationButtons';
import NoDataMsg from '../../common/components/NoDataMsg';
import { useFormik } from 'formik';
import { showLoader } from '../../services/loader.services';
import { addWorkingDay, getWorkingDayList } from '../../services/paySchedule.service';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import AlertService from '../../services/AlertService';
import dayjs from 'dayjs';
import { convertNumToMonth } from '../../services/common.service';

const WorkingDays = () => {
	useEffect(() => { }, []);

	const { userAccountId } = useContext(AuthContext);
	const [workingDayData, setWorkingDayData] = useState<any>([]);
	const [workingDayArray, setWorkingDayArray] = useState<any>([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(100);
	const { items, requestSort, getClassNamesFor } = useSortableData(workingDayData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const workingDayForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			workingDayDate: '',
		},
		validate: (values) => {
			const errors: {
				workingDayDate?: string;
			} = {};

			if (!values.workingDayDate) {
				errors.workingDayDate = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		// validateOnChange: false,
		onSubmit: () => { },
	});

	const filteredData: any = onCurrentPageData.filter(
		(i) =>
			(i.userType !== null &&
				i.userType
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.totalWorkingDays !== null &&
				i.totalWorkingDays
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())),
	);

	function viewWorkingDay() {
		setWorkingDayArray([]);
		setWorkingDayData([]);
		setDataSuccess(false);
		setIsLoader(true);
		let workingDayDate = workingDayForm.values.workingDayDate;
		const splitDate = workingDayDate.split('-');
		const year = splitDate[0];
		const month = splitDate[1];
		setYear(year);
		setMonth(month);
		getWorkingDay(year, month);
	}

	function getWorkingDay(year: any, month: any) {
		setIsLoader(true);
		getWorkingDayList(
			year,
			month,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workingDayList;
					if (data != undefined) {
						setWorkingDayData(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						setDataSuccess(false);
						setIsOpenListCard(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setWorkingDayData([]);
					setDataSuccess(true);
					setIsOpenListCard(false);
					setNoDataMsg(response.data.message);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	const updateTotalWorkingDay = (cell: number, workingDayId: any, totalWorkingDays: any) => {
		const newData: any = [];

		workingDayData.forEach((item: any, index: any) => {
			if (item['userTypeId'] == cell) {
				item['totalWorkingDays'] = totalWorkingDays;
			}
			newData.push(item);
		});
		setWorkingDayData(newData);

		if (workingDayArray.length == 0) {
			const obj = {
				userTypeId: cell,
				totalWorkingDays: totalWorkingDays,
				workingDayId: workingDayId,
			};
			workingDayArray.push(obj);
		} else {
			let selectedItem: any;
			workingDayArray.forEach((item: any, index: any) => {
				if (item['userTypeId'] == cell) {
					selectedItem = item;
				}
			});
			if (selectedItem != undefined) {
				selectedItem['totalWorkingDays'] = totalWorkingDays;
			} else {
				var obj;
				obj = {
					userTypeId: cell,
					totalWorkingDays: totalWorkingDays,
					workingDayId: workingDayId,
				};
				workingDayArray.push(obj);
			}
		}
	};

	function setWorkDayDetails() {
		let workingDayDate = workingDayForm.values.workingDayDate;
		const splitDate = workingDayDate.split('-');
		const year = splitDate[0];
		const month = splitDate[1];

		return {
			scheduleDetails: workingDayArray,
			licenseKey: getLicenseKey,
			year: year,
			month: month,
			staffDetailsId: userAccountId,
		};
	}

	function updateWorkingDaySubmit() {
		showLoader(true);
		if (workingDayArray.length > 0) {
			let workingDayPostData = setWorkDayDetails();

			addWorkingDay(
				workingDayPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (workingDayArray.length == 0) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function closeAndReset() {
		setIsOpenListCard(true);
		setDataSuccess(false);
		setWorkingDayArray([]);
		setWorkingDayData([]);
		workingDayForm.resetForm();
	}

	return (
		<PageWrapper title='Working Days'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card
						stretch
						data-tour='list'
						tag='form'
						noValidate
						onSubmit={workingDayForm.handleSubmit}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Update Working Days
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
								<div className='col-3'>
									<FormGroup id='workingDayDate' label='Month' isFloating>
										<Input
											onChange={workingDayForm.handleChange}
											value={workingDayForm.values.workingDayDate}
											onBlur={workingDayForm.handleBlur}
											isValid={workingDayForm.isValid}
											isTouched={workingDayForm.touched.workingDayDate}
											invalidFeedback={workingDayForm.errors.workingDayDate}
											type='month'
										/>
									</FormGroup>
								</div>

								<div className='col-3'>
									<Button
										className='mt-2'
										icon='ArrowDownward'
										color='primary'
										onClick={viewWorkingDay}
										isDisable={
											workingDayForm.values.workingDayDate != ''
												? false
												: true
										}>
										View
									</Button>
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<>
						<Card stretch data-tour='list'>
							<CardHeader borderSize={1}>
								<div className='d-flex col-md-5 justify-content-start'>
									<CardLabel icon='List' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											{convertNumToMonth(month)} - {year} Working Days List
										</CardTitle>
									</CardLabel>
								</div>
								<div className='d-flex col-md-7 justify-content-end'>
									<div
										className='d-flex col-8 justify-content-end'
										data-tour='search'>
										<ButtonGroup
											className='col-lg-8 d-print-none'
											color='primary'>
											<Icon
												className='mt-1'
												icon='Search'
												size='2x'
												color='primary'
											/>
											<Input
												id='searchInput'
												type='search'
												placeholder='Search...'
												onChange={columnVisibilityForm.handleChange}
												value={columnVisibilityForm.values.searchInput}
											/>
										</ButtonGroup>
									</div>
									&nbsp;&nbsp;
									<div className='col-md-2 justify-content-center'>
										<Button
											color='info'
											icon='Save'
											type='submit'
											isDisable={workingDayArray.length > 0 ? false : true}
											onClick={updateWorkingDaySubmit}>
											Save
										</Button>
									</div>
								</div>
							</CardHeader>
							<CardBody className='table-responsive'>
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th
												style={{ width: '5px' }}
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											<th
												style={{ width: '65px' }}
												scope='col'
												onClick={() => requestSort('userType')}
												className='cursor-pointer text-decoration-underline'>
												User Type
												<Icon
													size='lg'
													className={getClassNamesFor('userType')}
													icon='FilterList'
												/>
											</th>
											<th style={{ width: '15px' }}>Total Working Days</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((i: any, index: any) => (
													<tr key={i.userTypeId}>
														<td>{i.sno}</td>
														<td>{i.userType}</td>
														<td scope='col'>
															<FormGroup id='totalWorkingDays'>
																<Input
																	className='w-60'
																	placeholder=''
																	value={i.totalWorkingDays}
																	onInput={(e: any) =>
																		updateTotalWorkingDay(
																			i.userTypeId,
																			i.workingDayId,
																			e.target.value,
																		)
																	}
																/>
															</FormGroup>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg columnsCount={4} msg={noDataMsg} />
										)}
									</tbody>
								</table>
							</CardBody>
						</Card>
					</>
				) : null}
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default WorkingDays;
