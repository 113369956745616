import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import dayjs from 'dayjs';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';
import { TableLoader } from '../../../../services/loader.services';
import { priceFormat } from '../../../../helpers/helpers';
import { getStaffListForPayRun } from '../../../../services/salary.service';
import { getCurrentDateAndTime } from '../../../../services/common.service';

const TotalSalaryDetails = () => {
    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        if (userTypeId != '' && userTypeId != '') {
            const [year, month] = getCurrentDateAndTime('date').split('-');
            getStaffSalaryList(year, month);
        }
    }, []);


    const { themeStatus, darkModeStatus } = useDarkMode();
    const [dataStatus, setDataStatus] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [salaryDetails, setSalaryDetails] = useState<any>([]);
    const [totalSalaryAmount, setTotalSalaryAmount] = useState<any>(0);
    const [totalPaidSalaryAmount, setTotalPaidSalaryAmount] = useState<any>(0);
    const [totalUpPaidSalaryAmount, setTotalUpPaidSalaryAmount] = useState<any>(0);


    function getStaffSalaryList(year: any, month: any) {
        setDataStatus(false);
        getStaffListForPayRun(year, month, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListForPayRun;
                    if (data != undefined) {
                        setDataStatus(true);
                        setSalaryDetails(data);
                        let totalAmount1 = 0;
                        let totalSalaryAmount1 = 0;
                        let totalPending1 = 0;
                        for (let data1 of data) {
                            let totalSalaryAmount = Number(data1.totalSalaryAmount)
                            let collectionAmount = Number(data1.paidAmount)
                            let pendingAmount = Number(data1.pendingAmount)
                            totalSalaryAmount1 += totalSalaryAmount;
                            totalAmount1 += collectionAmount;
                            totalPending1 += pendingAmount;
                        }
                        setTotalSalaryAmount(totalSalaryAmount1);
                        setTotalPaidSalaryAmount(totalAmount1);
                        setTotalUpPaidSalaryAmount(totalPending1);
                    } else {
                        setDataStatus(true);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setDataStatus(true);
                    setSalaryDetails([]);
                }
            },
            (error) => {
                setDataStatus(true);
                toasts(error, 'Error');
            },
        );
    }

    return (
        <Card>
            <CardHeader>
                <CardLabel icon='ShowChart' iconColor='secondary'>
                    <CardTitle tag='div' className='h5'>
                        Salary Details
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                {!dataStatus ?
                    <TableLoader /> :
                    <div className='row g-4 align-items-center'>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-success': !darkModeStatus,
                                        'bg-lo25-success': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='Checklist' size='3x' color='success' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{priceFormat(totalSalaryAmount)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                        Total Salary
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-primary': !darkModeStatus,
                                        'bg-lo25-primary': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon
                                        icon='Money'
                                        size='3x'
                                        color='primary'
                                    />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'> {priceFormat(totalPaidSalaryAmount)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                    Total Paid Salary Amount
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-danger': !darkModeStatus,
                                        'bg-lo25-danger': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='Report' size='3x' color='danger' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{priceFormat(totalUpPaidSalaryAmount)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                        Total Un Payable Amount
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-warning': !darkModeStatus,
                                        'bg-lo25-warning': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='GridView' size='3x' color='info' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{dashboardDataByUserTypeData.getTodayReceivedAmountByOthers != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayReceivedAmountByOthers) : 0}</div>
                                    <div className='text-muted mt-n1'>Others</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                }
            </CardBody>
        </Card>
    );
};

export default TotalSalaryDetails;
