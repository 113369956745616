
export const getAPIURL = () => {

    // console.log("Welcome React");
    // console.log(process.env.NODE_ENV);
    var environment = process.env.NODE_ENV
    // console.log("Environment : " + environment);

    // const apiUrl = 'http://localhost/omniwheels/bb-payroll-api/';
    const apiUrl = 'https://api.payroll-dev.suncolleges.ac.in/';

    return apiUrl;
}

// getLicenseKey
export const getLicenseKey = 5566;