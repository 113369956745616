import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import DeleteComponents from "../../../common/components/DeleteComponents";
import NoDataMsg from "../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../common/components/OpenCardComponents";
import SearchableSelect from "../../../common/components/SearchableSelect";
import AuthContext from "../../../contexts/authContext";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import { getLicenseKey } from "../../../services/application.settings";
import { getCurrentDateAndTime, updateFilter, getStatus, getColumnsForDataTable, updateColumnsForDataTable, onlyAllowNumber } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/export.service";
import { showLoader, TableLoader } from "../../../services/loader.services";
import { getSalaryAdvanceList, addSalaryAdvance, updateSalaryAdvance, deleteSalaryAdvance } from "../../../services/salary.service";
import { getProfileForStaff, getStaffListByUserTypeId, getStaffSalaryAdvanceRequestHistory } from "../../../services/staff.service";
import { toasts } from "../../../services/toast.service";
import { getUserType } from "../../../services/userType.service";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Collapse from "../../bootstrap/Collapse";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Modal, { ModalHeader, ModalTitle, ModalBody } from "../../bootstrap/Modal";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Textarea from "../../bootstrap/forms/Textarea";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";



function SalaryAdvance() {

  const { userAccountId, userTypeId } = useContext(AuthContext);

  useEffect(() => {
    getUserTypeList();
    if (userTypeId != 1) {
      setIsLoader(true);
      setDataSuccess(true);
      getSalaryAdvance(userTypeId, userAccountId)

    }
  }, []);

  const TABS = {
    CASH_PAYMENTS: 'Cash',
    CHEQUE: 'Cheque',
    NEFT_UPI: 'NEFT / UPI',
    CARD_PAYMENT: 'Card',
  };

  const [activeTab, setActiveTab] = useState(TABS.CASH_PAYMENTS);

  const getNextMonthDate = (dateString: any) => {
    const date = new Date(dateString);

    // Set the month to the next month
    const nextMonth = date.getMonth() + 1;

    // Set the date object to the 14th of the next month
    date.setMonth(nextMonth);
    date.setDate(14);

    return date.toISOString().split('T')[0]; // Returns "YYYY-MM-DD"
  };

  const salaryAdvanceForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      staffDetailsId: '',
      currentSalaryAmount: '',
      requestedAmount: '',
      requestedDate: getCurrentDateAndTime('date'),
      reason: '',
      deductionMonth: '1',
      approvalStaff: '',
      deductionDate: getNextMonthDate(getCurrentDateAndTime('date'))
    },
    validate: (values) => {
      const errors: {
        staffDetailsId?: string;
        currentSalaryAmount?: string;
        requestedAmount?: string;
        requestedDate?: string;
        reason?: string;
        deductionMonth?: string;
        approvalStaff?: string;
        deductionDate?: string;
      } = {};

      if (userTypeId == 1) {
        if (!staffDetailsIdForAdd) {
          errors.staffDetailsId = 'Required';
        }
      }
      // if (!values.currentSalaryAmount) {
      //   errors.currentSalaryAmount = 'Required';
      // }
      if (!values.requestedAmount) {
        errors.requestedAmount = 'Required';
      } else if (Number(values.requestedAmount) > Number(currentMonthSalary)) {
        errors.requestedAmount = 'Please Check Amount';
      }
      if (!values.requestedDate) {
        errors.requestedDate = 'Required';
      }
      if (!values.deductionMonth) {
        errors.deductionMonth = 'Required';
      }
      if (!values.reason) {
        errors.reason = 'Required';
      }
      if (!values.deductionDate) {
        errors.deductionDate = 'Required';
      }
      if (!approvalStaff) {
        errors.approvalStaff = 'Required';
      }
      return errors;
    },
    // validateOnChange: true,
    onSubmit: () => { addSalaryAdvanceSubmit() },
  });

  const editSalaryAdvanceForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      staffDetailsId: '',
      currentSalaryAmount: '',
      requestedAmount: '',
      requestedDate: getCurrentDateAndTime('date'),
      reason: '',
      deductionMonth: '',
      approvalStaff: '',
      remarks: '',
      salaryAdvanceId: '',
      statusId: '',
      deductionDate: '',
    },
    validate: (values) => {
      const errors: {
        staffDetailsId?: string;
        currentSalaryAmount?: string;
        requestedAmount?: string;
        requestedDate?: string;
        reason?: string;
        deductionMonth?: string;
        approvalStaff?: string;
        remarks?: string;
        statusId?: string;
        deductionDate?: string;
      } = {};
      if (userTypeId == 1) {
        if (!staffDetailsIdForAdd) {
          errors.staffDetailsId = 'Required';
        }
      }
      if (!values.currentSalaryAmount) {
        errors.currentSalaryAmount = 'Required';
      }
      if (!values.requestedAmount) {
        errors.requestedAmount = 'Required';
      } else if (Number(values.requestedAmount) > Number(values.currentSalaryAmount)) {
        errors.requestedAmount = 'Please Check Amount';
      }
      if (!values.requestedDate) {
        errors.requestedDate = 'Required';
      }
      if (!values.deductionMonth) {
        errors.deductionMonth = 'Required';
      }
      if (!values.reason) {
        errors.reason = 'Required';
      }
      if (!values.deductionDate) {
        errors.deductionDate = 'Required';
      }

      // if (isDisposal == 0) {
      if (!approvalStaff) {
        errors.approvalStaff = 'Required';
      }
      // }
      if (statusId?.value != 1) {
        if (!values.remarks) {
          errors.remarks = 'Required';
        }
      }
      return errors;
    },
    // validateOnChange: true,
    onSubmit: () => { updateSalaryAdvanceSubmit() },
  });

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },
    validateOnChange: false,
    onSubmit: () => { },
  });

  const payFeesDenominationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      staffDetailsId: '',
      currentSalaryAmount: '',
      requestedAmount: '',
      requestedDate: getCurrentDateAndTime('date'),
      reason: '',
      deductionMonth: '',
      approvalStaff: '',
      remarks: '',
      salaryAdvanceId: '',
      statusId: '',
      deductionDate: '',

      receiptDate: getCurrentDateAndTime('date'),
      paymentTypeId: '',
      twoThousand_total: '',
      twoThousand_count: '',
      thousand_total: '',
      thousand_count: '',
      fiveHundred_total: '',
      fiveHundred_count: '',
      twoHundred_total: '',
      twoHundred_count: '',
      hundred_total: '',
      hundred_count: '',
      fifty_total: '',
      fifty_count: '',
      twenty_total: '',
      twenty_count: '',
      ten_total: '',
      ten_count: '',
      five_total: '',
      five_count: '',
      totalAmount: '',
      chequeNumber: '',
      chequeDate: getCurrentDateAndTime('date'),
      chequeAmount: '',
      chequeBankName: '',
      neftRefNumber: '',
      neftTranDate: getCurrentDateAndTime('date'),
      neftAmount: '',
      neftBankName: '',
      cardNumber: '',
      cardDate: getCurrentDateAndTime('date'),
      cardAmount: '',
      cardBankName: '',
      balCashAmount: '',
      narration: ''
    },
    // validate: (values) => {
    // },
    //validateOnChange: false,
    onSubmit: () => { updateSalaryAdvanceDisposalSubmit() },
  });

  const { themeStatus, darkModeStatus } = useDarkMode();
  const [isLoader, setIsLoader] = useState(false);
  const [isOpenListCard, setIsOpenListCard] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [addAdvanceSalaryCanvas, setAddAdvanceSalaryCanvas] = useState(false);
  const [editAdvanceSalaryCanvas, setEditAdvanceSalaryCanvas] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [userTypeData, setUserTypeData] = useState<any>([])
  const [staffDetailsData, setStaffDetailsData] = useState<any>([])
  const [staffDetailsId, setStaffDetailsId] = useState<any>('')
  const [allStaffData, setAllStaffData] = useState<any>([]);
  const [salaryAdvanceData, setSalaryAdvanceData] = useState<any>([]);
  const [statusData, setStatusData] = useState<any>([]);
  const [noDataMsg, setNoDataMsg] = useState('')
  const [staffDetailsIdForAdd, setStaffDetailsIdForAdd] = useState<any>('')
  const [approvalStaff, setApprovalStaff] = useState<any>('')
  const [statusId, setStatusId] = useState<any>('')
  const [checkStatusForEdit, setCheckStatusForEdit] = useState<any>('')
  const [isDisposal, setIsDisposal] = useState<any>('')
  const [approvalStaffListData, setApprovalStaffListData] = useState<any>([]);

  // For Alert
  const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
  const [isOpen, setIsOpen] = useState(false);

  const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
  const componentRef = useRef(null);
  const { items, requestSort, getClassNamesFor } = useSortableData(salaryAdvanceData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [columnVisibilityData, setColumnVisibilityData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [columnDataById, setColumnDataById] = useState([]);
  const [staffRequestHistoryData, setStaffRequestHistoryData] = useState([]);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' });
  const [disposalModal, setDisposalModal] = useState(false)
  const navigate = useNavigate();

  const [paymentTypeId, setPaymentTypeId] = useState<any>("")
  const [fiveHundred_total, setFiveHundred_total] = useState<any>("")
  const [twoHundred_total, setTwoHundred_total] = useState<any>("")
  const [hundred_total, setHundred_total] = useState<any>("")
  const [fifty_total, setFifty_total] = useState<any>("")
  const [twenty_total, setTwenty_total] = useState<any>("")
  const [ten_total, setTen_total] = useState<any>("")
  const [five_total, setFive_total] = useState<any>("")
  const [totalAmount, setTotalAmount] = useState<any>("")
  const [balCashAmount, setBalCashAmount] = useState<any>("")
  const [advanceSalaryTotalAmount, setAdvanceSalaryTotalAmount] = useState<any>(0)
  const [currentMonthSalary, setCurrentMonthSalary] = useState<any>(0)

  // Filter
  const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

  function openAddLoanOffCanvas() {
    getStatusList();
    getStaffList(0);
    setAddAdvanceSalaryCanvas(true)
    resetForm()

    if (staffDetailsId?.value != undefined) {
      setStaffDetailsIdForAdd(staffDetailsId)
      getStaffProfile(staffDetailsId?.value)
    }
  }

  const selectUserType = (e: any) => {
    setStaffDetailsId('')
    setDataSuccess(false)
    setIsOpenListCard(true)
    let userTypeId = e
    setStaffTypeId(userTypeId)
    if (userTypeId?.value != undefined) {
      getStaffListByUserType(userTypeId?.value);
    }
  }

  const selectStaffDetailsId = (e: any) => {
    let staffDetailsId = e
    setStaffDetailsId(staffDetailsId)
  }

  function selectSalaryAdvanceList() {
    setDataSuccess(false);
    setIsLoader(true);
    getSalaryAdvance(staffTypeId?.value, staffDetailsId?.value != undefined ? staffDetailsId?.value : 0)
  };

  function onActivate(i: any, modal: any) {
    if (i != undefined) {
      let selectedSalaryAdvance = i

      if (modal == 'edit') {
        setEditAdvanceSalaryCanvas(true)
        setIsDisposal(0)
      } else {
        setDisposalModal(true)
        setIsDisposal(1)
      }

      setStaffDetailsIdForAdd({ value: selectedSalaryAdvance.staffDetailsId, label: selectedSalaryAdvance.empAndStaffName })
      setStatusId({ value: selectedSalaryAdvance.statusId, label: selectedSalaryAdvance.statusName })
      setApprovalStaff({ value: selectedSalaryAdvance.assignedBy, label: selectedSalaryAdvance.assignedStaff })
      setCheckStatusForEdit(selectedSalaryAdvance.statusId)
      setAdvanceSalaryTotalAmount(selectedSalaryAdvance.requestedAmount)
      editSalaryAdvanceForm.setValues({
        staffDetailsId: selectedSalaryAdvance.staffDetailsId,
        currentSalaryAmount: selectedSalaryAdvance.currentSalaryAmount,
        requestedAmount: selectedSalaryAdvance.requestedAmount,
        requestedDate: selectedSalaryAdvance.requestedDate,
        reason: selectedSalaryAdvance.reason,
        deductionMonth: selectedSalaryAdvance.deductionMonth,
        deductionDate: selectedSalaryAdvance.deductionDate,
        approvalStaff: selectedSalaryAdvance.assignedBy,
        statusId: selectedSalaryAdvance.statusId,
        salaryAdvanceId: selectedSalaryAdvance.salaryAdvanceId,
        remarks: ''
      });

      payFeesDenominationForm.setValues({
        staffDetailsId: selectedSalaryAdvance.staffDetailsId,
        currentSalaryAmount: selectedSalaryAdvance.currentSalaryAmount,
        requestedAmount: selectedSalaryAdvance.requestedAmount,
        requestedDate: selectedSalaryAdvance.requestedDate,
        reason: selectedSalaryAdvance.reason,
        deductionMonth: selectedSalaryAdvance.deductionMonth,
        deductionDate: selectedSalaryAdvance.deductionDate,
        approvalStaff: selectedSalaryAdvance.assignedBy,
        statusId: selectedSalaryAdvance.statusId,
        salaryAdvanceId: selectedSalaryAdvance.salaryAdvanceId,
        remarks: selectedSalaryAdvance.remarks,
        receiptDate: getCurrentDateAndTime('date'),
        paymentTypeId: '',
        twoThousand_total: '',
        twoThousand_count: '',
        thousand_total: '',
        thousand_count: '',
        fiveHundred_total: '',
        fiveHundred_count: '',
        twoHundred_total: '',
        twoHundred_count: '',
        hundred_total: '',
        hundred_count: '',
        fifty_total: '',
        fifty_count: '',
        twenty_total: '',
        twenty_count: '',
        ten_total: '',
        ten_count: '',
        five_total: '',
        five_count: '',
        totalAmount: '',
        chequeNumber: '',
        chequeDate: getCurrentDateAndTime('date'),
        chequeAmount: '',
        chequeBankName: '',
        neftRefNumber: '',
        neftTranDate: getCurrentDateAndTime('date'),
        neftAmount: '',
        neftBankName: '',
        cardNumber: '',
        cardDate: getCurrentDateAndTime('date'),
        cardAmount: '',
        cardBankName: '',
        balCashAmount: '',
        narration: ''

      });

      getStatusList();
      getStaffList(0);
    }
  }

  function getSalaryAdvance(userTypeId: any, staffDetailsId: any) {
    setIsLoader(true);
    getSalaryAdvanceList(userTypeId, staffDetailsId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.salaryAdvanceList;
          if (data != undefined) {
            getColumnsForTable('getSalaryAdvanceList', 'get');
            setSalaryAdvanceData(data);
            setIsLoader(false);
            setDataSuccess(true);
            setIsOpenListCard(false);
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setIsLoader(false);
            setDataSuccess(true);
            toasts('Undefined Data', 'Error');

          }
        } else if (response.data.success === false) {
          setSalaryAdvanceData([]);
          getColumnsForTable('getSalaryAdvanceList', 'get');
          setIsLoader(false);
          setDataSuccess(true);
          setIsOpenListCard(false);
          setNoDataMsg(response.data.message)
        }
      },
      (error) => {
        setIsLoader(false);
        setDataSuccess(true);
        toasts(error, 'Error');

      },
    );
  }

  function setSalaryAdvanceDetails() {
    return ({
      staffDetailsId: userTypeId != 1 ? userAccountId : staffDetailsIdForAdd?.value,
      assignedBy: approvalStaff?.value,
      currentSalaryAmount: currentMonthSalary,
      requestedAmount: salaryAdvanceForm.values.requestedAmount,
      requestedDate: salaryAdvanceForm.values.requestedDate,
      reason: salaryAdvanceForm.values.reason,
      deductionMonth: salaryAdvanceForm.values.deductionMonth,
      deductionDate: salaryAdvanceForm.values.deductionDate,
      licenseKey: getLicenseKey,
      userAccountId: userAccountId
    })
  }

  function addSalaryAdvanceSubmit() {
    showLoader(true);
    if (salaryAdvanceForm.isValid) {
      let salaryAdvancePostData = setSalaryAdvanceDetails()
      addSalaryAdvance(salaryAdvancePostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "success" });
            setIsOpen(true);
            closeAndResetForm();
            if (userTypeId == 1) {
              selectSalaryAdvanceList()
            } else {
              getSalaryAdvance(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
            }
          }
          else if (data.success == false) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "warning" });
            setIsOpen(true);
          }
          else {
            showLoader(false);
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            setAlertStatus({ message: errorDescription, type: "error" });
            setIsOpen(true);
          }
        }
        , (error) => {
          setAlertStatus({ message: error, type: "error" });
          setIsOpen(true);
        }
      )

    } else if (salaryAdvanceForm.isValid == false) {
      showLoader(false);
      setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
      setIsOpen(true);
    }
  }

  function setUpdateSalaryAdvanceDetails() {
    return ({
      staffDetailsId: staffDetailsIdForAdd?.value,
      assignedBy: approvalStaff?.value,
      statusId: statusId?.value,
      salaryAdvanceId: editSalaryAdvanceForm.values.salaryAdvanceId,
      currentSalaryAmount: editSalaryAdvanceForm.values.currentSalaryAmount,
      requestedAmount: editSalaryAdvanceForm.values.requestedAmount,
      requestedDate: editSalaryAdvanceForm.values.requestedDate,
      deductionMonth: editSalaryAdvanceForm.values.deductionMonth,
      deductionDate: editSalaryAdvanceForm.values.deductionDate,
      reason: editSalaryAdvanceForm.values.reason,
      remarks: editSalaryAdvanceForm.values.remarks,
      isDisposal: isDisposal,

      /// Disposal Denomination 
      paymentTypeId: paymentTypeId,
      twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
      twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
      thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
      thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
      fivehunderd_total: Number(fiveHundred_total) != 0 ? Number(fiveHundred_total) : null,
      fivehunderd_count: Number(payFeesDenominationForm.values.fiveHundred_count) != 0 ? Number(payFeesDenominationForm.values.fiveHundred_count) : null,
      twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
      twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
      hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
      hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
      fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
      fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
      twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
      twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
      ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
      ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
      five_total: Number(five_total) != 0 ? Number(five_total) : null,
      five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
      totalAmount: Number(advanceSalaryTotalAmount),

      /// Cheque Payment ///
      chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
      chequeDate: paymentTypeId == 2 ? payFeesDenominationForm.values.chequeDate : null,
      chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
      chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

      /// Neft Payment ///
      neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
      neftTranDate: paymentTypeId == 3 ? payFeesDenominationForm.values.neftTranDate : null,
      neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
      neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

      /// Card Payment ///
      cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.cardNumber : null,
      cardDate: paymentTypeId == 4 ? payFeesDenominationForm.values.cardDate : null,
      cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
      cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

      narration: payFeesDenominationForm.values.narration,
      balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,


      licenseKey: getLicenseKey,
      userAccountId: userAccountId
    })
  }

  function setDelateSalaryAdvanceDetails() {
    return ({

      salaryAdvanceId: editSalaryAdvanceForm.values.salaryAdvanceId,
      licenseKey: getLicenseKey,
      userAccountId: userAccountId
    })
  }

  function updateSalaryAdvanceSubmit() {
    showLoader(true);
    // if (checkStatusForEdit == 1) {
    if (editSalaryAdvanceForm.isValid) {
      let salaryAdvancePostData = setUpdateSalaryAdvanceDetails()
      updateSalaryAdvance(salaryAdvancePostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "success" });
            setIsOpen(true);
            if (userTypeId == 1) {
              selectSalaryAdvanceList()
            } else {
              getSalaryAdvance(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
            }
            closeAndResetForm();
          }
          else if (data.success == false) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "warning" });
            setIsOpen(true);
          }
          else {
            showLoader(false);
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            setAlertStatus({ message: errorDescription, type: "error" });
            setIsOpen(true);
          }
        }
        , (error) => {
          showLoader(false);
          setAlertStatus({ message: error, type: "error" });
          setIsOpen(true);
        }
      )

    } else if (editSalaryAdvanceForm.isValid == false) {
      showLoader(false);
      setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
      setIsOpen(true);
    }
    // } else {
    //   showLoader(false);
    //   setAlertStatus({ message: 'Salary Advance Request Approved - Details Not Updated !', type: "error" });
    //   setIsOpen(true);
    // }
  }


  function updateSalaryAdvanceDisposalSubmit() {
    showLoader(true);
    // if (checkStatusForEdit == 1) {
    if (payFeesDenominationForm.isValid) {
      let salaryAdvancePostData = setUpdateSalaryAdvanceDetails()
      updateSalaryAdvance(salaryAdvancePostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "success" });
            setIsOpen(true);
            if (userTypeId == 1) {
              selectSalaryAdvanceList()
            } else {
              getSalaryAdvance(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
            }
            closeAndResetForm();
          }
          else if (data.success == false) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "warning" });
            setIsOpen(true);
          }
          else {
            showLoader(false);
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            setAlertStatus({ message: errorDescription, type: "error" });
            setIsOpen(true);
          }
        }
        , (error) => {
          showLoader(false);
          setAlertStatus({ message: error, type: "error" });
          setIsOpen(true);
        }
      )

    } else if (payFeesDenominationForm.isValid == false) {
      showLoader(false);
      setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
      setIsOpen(true);
    }
    // } else {
    //   showLoader(false);
    //   setAlertStatus({ message: 'Salary Advance Request Approved - Details Not Updated !', type: "error" });
    //   setIsOpen(true);
    // }
  }


  function deleteSalaryAdvanceSubmit() {
    showLoader(true);
    let salaryAdvancePostData = setDelateSalaryAdvanceDetails()
    deleteSalaryAdvance(salaryAdvancePostData,
      (response) => {
        const data = response.data;
        if (data.success == true) {
          showLoader(false);
          setAlertStatus({ message: data.message, type: "success" });
          setIsOpen(true);
          closeAndResetForm();
          if (userTypeId == 1) {
            selectSalaryAdvanceList()
          } else {
            getSalaryAdvance(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
          }
        }
        else if (data.success == false) {
          showLoader(false);
          setAlertStatus({ message: data.message, type: "warning" });
          setIsOpen(true);
        }
        else {
          showLoader(false);
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setAlertStatus({ message: errorDescription, type: "error" });
          setIsOpen(true);
        }
      }
      , (error) => {
        setAlertStatus({ message: error, type: "error" });
        setIsOpen(true);
      }
    )
  }

  function getUserTypeList() {
    getUserType(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.userType;
          if (data != undefined) {
            const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
            data.forEach((obj: { userTypeId: number; userType: string }) => {
              let userType = userTypeData.push(obj);
            });
            setUserTypeData(userTypeData);

          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          //toasts(response.data.message, "Error")
          setUserTypeData([]);
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getStatusList() {
    getStatus(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.status;
          if (data != undefined) {
            setStatusData(data);

          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          //toasts(response.data.message, "Error")
          setStatusData([]);
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getStaffList(userTypeId: any) {
    getStaffListByUserTypeId(
      userTypeId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.staffListByUserTypeId;
          if (data != undefined) {
            setAllStaffData(data);
            setApprovalStaffListData(data.filter((item: any) => item.userTypeId != 7 && item.userTypeId != 9 && item.userTypeId != 10 && item.userTypeId != 11));
          } else {
            toasts('Undefined Data', 'Error');
          }
        } else if (response.data.success === false) {
          setAllStaffData([]);
          setApprovalStaffListData([]);
          //toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, 'Error');
        }
      },
      (error) => {
        toasts(error, 'Error');
      },
    );
  }

  function getSalaryAdvanceRequestHistory(requestTableId: any) {
    getStaffSalaryAdvanceRequestHistory(requestTableId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.staffRequestHistory;
          if (data != undefined) {
            setStaffRequestHistoryData(data);
            setStatusModal(true)
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          //toasts(response.data.message, "Error")
          setStaffRequestHistoryData([]);
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getStaffListByUserType(userTypeId: any) {
    getStaffListByUserTypeId(userTypeId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.staffListByUserTypeId;
          if (data != undefined) {
            const staffDetailsData = [{ staffDetailsId: 0, empAndStaffName: 'Select All' }];
            data.forEach((obj: { staffDetailsId: number; empAndStaffName: string }) => {
              let staffDetails1 = staffDetailsData.push(obj);
            });
            setStaffDetailsData(staffDetailsData);
            setStaffDetailsId({ value: 0, label: 'Select All' })
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setStaffDetailsData([]);
          setStaffDetailsId([])
          //toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }


  function getColumnsForTable(apiName: any, type: any) {
    getColumnsForDataTable(userTypeId, apiName,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.columnsForDataTable;
          if (data != undefined) {
            let allColumnsData = data;
            setAllColumnsData(allColumnsData)
            let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
            setColumnVisibilityData(columnVisibilityData)

            if (type == 'get') {
              const allData = allColumnsData.map((item: any) => item.isDisplay);
              const allColumns = allData.filter((item: any) => item == false);
              setIsChecked(allColumns == '' ? true : false);
            }
            else if (type == 'post') {
              const all = [];
              let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

              for (let i = 0; i < nonDefaultValue.length; i++) {

                const obj = {
                  columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                  isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                }
                all.push(obj);
              }
              updateColumnsSubmit('', '', all)
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )

  }

  function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

    if (columnVisibilityForm.isValid) {

      setColumnDataById(isDisplay)
      const columnVisibilityPostData = {
        licenseKey: getLicenseKey,
        userAccountId: userAccountId,
        columns: type ? type : [
          {
            columnVisibilityId: columnVisibilityId,
            isDisplay: isDisplay ? 0 : 1
          }
        ]
      }

      updateColumnsForDataTable(columnVisibilityPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            getColumnsForTable('getSalaryAdvanceList', 'get');
          }
          else if (data.success == false) {
            // toasts(data.success, "Error")
          }
          else {
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            toasts(errorDescription, "Error")
          }
        }
        , (error) => {
          toasts(error, "Error")
        }
      )
    } else if (columnVisibilityForm.isValid == false) {
      toasts("Please fill all the details!", "Error")
    }
  }

  function closeAndResetForm() {
    setStatusModal(false)
    setDisposalModal(false)
    setAddAdvanceSalaryCanvas(false)
    setEditAdvanceSalaryCanvas(false)
    resetForm()
    formReset()
  }

  function resetForm() {
    setStaffDetailsIdForAdd('')
    setCurrentMonthSalary(0)
    setApprovalStaff('')
    setStatusId('')
    setIsDisposal(0)
    editSalaryAdvanceForm.resetForm()
    salaryAdvanceForm.resetForm()
  }

  const multiply500 = (e: any) => {
    const count500 = e.target.value;
    setFiveHundred_total(count500 * 500)
    multiply(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    balanceAmountCal(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
  }

  const multiply200 = (e: any) => {
    const count200 = e.target.value;
    setTwoHundred_total(count200 * 200);
    multiply(fiveHundred_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    balanceAmountCal(fiveHundred_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)

  }

  const multiply100 = (e: any) => {
    const count100 = e.target.value;
    setHundred_total(count100 * 100);
    multiply(fiveHundred_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
    balanceAmountCal(fiveHundred_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)

  }

  const multiply50 = (e: any) => {
    const count50 = e.target.value;
    setFifty_total(count50 * 50);
    multiply(fiveHundred_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)
    balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)

  }

  const multiply20 = (e: any) => {
    const count20 = e.target.value;
    setTwenty_total(count20 * 20);
    multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)
    balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)

  }

  const multiply10 = (e: any) => {
    const count10 = e.target.value;
    setTen_total(count10 * 10);
    multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
    balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
  }

  const multiply5 = (e: any) => {
    const count5 = e.target.value;
    setFive_total(count5 * 5);
    multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
    balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
  }

  const multiply = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
    setTotalAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total));
  }

  const balanceAmountCal = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
    setBalCashAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(advanceSalaryTotalAmount));
  }

  const balanceAmountCal1 = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any, advanceSalaryTotalAmount: any) => {
    setBalCashAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(advanceSalaryTotalAmount));
  }

  function formReset() {
    payFeesDenominationForm.resetForm();
    setPaymentTypeId(0)
    setFiveHundred_total("")
    setTwoHundred_total("")
    setHundred_total("")
    setFifty_total("")
    setTwenty_total("")
    setTen_total("")
    setFive_total("")
    setTotalAmount("")
    setBalCashAmount("")
  }

  function selectStaffForAdd(e: any) {
    setStaffDetailsIdForAdd(e)
    setCurrentMonthSalary(0)
    if (e?.value != undefined) {
      getStaffProfile(e.value)
    }
  }

  function getStaffProfile(staffDetailsId: any) {
    getProfileForStaff(
      staffDetailsId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.profileForStaff;
          if (data != undefined) {
            setCurrentMonthSalary(data[0].currentMonthSalary);

          } else {
            toasts('Undefined Data', 'Error');
          }
        } else if (response.data.success === false) {
          toasts(response.data.message, 'warning');
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, 'Error');
        }
      },
      (error) => {
        toasts(error, 'Error');
      },
    );
  }


  // Export Configuration
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (

    <PageWrapper title='Salary Advance'>
      <Page container='fluid'>

        {userTypeId != 1 ? <></> :
          <>
            <Collapse isOpen={isOpenListCard}>
              <Card stretch data-tour='list'>
                <CardHeader borderSize={1}>
                  <CardLabel icon='List' iconColor='info'>
                    <CardTitle tag='div' className='h5'>
                      Salary Advance List
                    </CardTitle>
                  </CardLabel>
                  <CardActions className='d-print-none'>
                    <Button color='primary' icon='Add' isLight onClick={openAddLoanOffCanvas}>
                      Add New
                    </Button>
                  </CardActions>
                </CardHeader>
                <CardBody>
                  <div className='row'>
                    <div className='col-3'>
                      <FormGroup id='userTypeId' label='Staff Type' isFloating>
                        <SearchableSelect isFloating
                          ariaLabel='Staff Type'
                          placeholder="Select Staff Type"
                          onChange={selectUserType}
                          value={staffTypeId}
                          list={userTypeData.map((data: any) => ({ value: data.userTypeId, label: data.userType }))} />
                      </FormGroup>
                    </div>

                    {staffTypeId?.value != 0 ?

                      <div className='col-4'>
                        <FormGroup id='staffDetailsId' label='Staff' isFloating>
                          <SearchableSelect isFloating
                            ariaLabel='Staff'
                            placeholder="Select Staff"
                            onChange={(e: any) => selectStaffDetailsId(e)}
                            value={staffDetailsId}
                            list={staffDetailsData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))} />
                        </FormGroup>
                      </div>
                      : null}

                    {staffTypeId?.value != undefined ?
                      <div className="col-3">
                        <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={selectSalaryAdvanceList}>View</Button>
                      </div>
                      : null}
                  </div>
                </CardBody>
              </Card>
            </Collapse>

            <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
          </>}

        {dataSuccess ? (
          <>
            <Card stretch data-tour='list' ref={componentRef} id='pdf'>
              <CardHeader borderSize={1}>
                <CardLabel>
                  <CardTitle>
                    <Icon icon='PlaylistAdd' size='2x' color='primary' />
                    &nbsp; Salary Advance
                  </CardTitle>
                </CardLabel>
                <CardActions className='d-print-none'>
                  <ButtonGroup color='primary'>
                    <Icon className='mt-1' icon='Search' size='2x' color='primary' />
                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                  </ButtonGroup>
                  <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                    <DropdownToggle>
                      <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                    </DropdownToggle>
                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                      <DropdownItem>
                        <div className='container py-2'>
                          <form className='row g-3'>
                            <div className='col-12'>
                              <FormGroup>
                                <h6>Select All</h6>
                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getSalaryAdvanceList', 'post')} checked={isChecked} ariaLabel='Available status' />
                              </FormGroup>
                            </div>
                            <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                              <FormGroup>
                                <h6>Columns</h6>
                                <ChecksGroup>
                                  {allColumnsData.map((i: any) => (
                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                  ))}
                                </ChecksGroup>
                              </FormGroup>
                            </div>
                          </form>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown isButtonGroup>
                    <DropdownToggle>
                      <Button color='primary' isLight icon='CloudDownload'>
                        Export
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu isAlignmentEnd>
                      <DropdownItem>
                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('SalaryAdvanceList', salaryAdvanceData, columnVisibilityData)}>
                          Excel
                        </Button>
                      </DropdownItem>
                      <DropdownItem>
                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(salaryAdvanceData, columnVisibilityData, 'SalaryAdvanceList')}>
                          PDF
                        </Button>
                      </DropdownItem>
                      <DropdownItem>
                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('SalaryAdvanceList', salaryAdvanceData, columnVisibilityData)}>
                          CSV
                        </Button>
                      </DropdownItem>
                      <DropdownItem>
                        <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                          Print
                        </Button>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {userTypeId != 1 ? <Button color='primary' icon='Add' isLight onClick={openAddLoanOffCanvas}>
                    Add New
                  </Button> : null}
                </CardActions>
              </CardHeader>
              <CardBody className='table-responsive' isScrollable>
                {isLoader ?
                  <TableLoader /> :
                  <table className='table table-modern table-hover'>
                    <thead>
                      <tr>
                        <th
                          scope='col'
                          onClick={() => requestSort('sno')}
                          className='cursor-pointer text-decoration-underline'>
                          S.No{' '}
                          <Icon
                            size='lg'
                            className={getClassNamesFor('sno')}
                            icon='FilterList'
                          />
                        </th>
                        {columnVisibilityData.map((column: any) => (
                          <th
                            key={column.keyName}
                            scope='col'
                            onClick={() => requestSort(column.keyName)}
                            className='cursor-pointer text-decoration-underline'>
                            {column.columnName}
                            <Icon
                              size='lg'
                              className={getClassNamesFor(column.keyName)}
                              icon='FilterList'
                            />
                          </th>
                        ))}
                        <th scope='col' className='d-print-none text-nowrap'>
                          Status
                        </th>
                        <th scope='col' className='d-print-none text-nowrap'>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData != "" ?
                        <>
                          {filteredData.map((rowData: any) => (
                            <tr key={rowData.salaryAdvanceId}>
                              <td width={'1%'}>{rowData.sno}</td>
                              {columnVisibilityData.map((column: any) => (
                                (column.isDisplay && rowData[column.keyName]) ?
                                  <td key={`${rowData.salaryAdvanceId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                              ))}
                              <td className='d-print-none'><Button isLink icon='Circle' className='text-nowrap' color={rowData.statusId == 1 ? 'info' : rowData.statusId == 2 ? 'success' : 'danger'} onClick={() => getSalaryAdvanceRequestHistory(rowData.salaryAdvanceId)}>{rowData.statusName}</Button></td>
                              <td className="d-print-none text-nowrap">
                                {rowData.isComplete == 1 ?
                                  <Button isLink icon='DoneAll' isLight className='text-nowrap' color='success' onClick={() => navigate(`../salaryAdvance/paySchedule/${rowData.salaryAdvanceId}`)}>Completed</Button>
                                  : <>
                                    {rowData.isDisposal == 'Yes' ?
                                      <Button color='primary' isLight icon='Preview' aria-label='Edit' onClick={() => navigate(`../salaryAdvance/paySchedule/${rowData.salaryAdvanceId}`)} >View</Button> :
                                      <>
                                        {rowData.statusId == 2 ?
                                          <Button color='primary' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData, 'disposal') }} >Disposal</Button> :
                                          <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData, 'edit') }} />}
                                      </>}
                                  </>}
                              </td>
                            </tr>
                          ))
                          }</>
                        :
                        <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}
                    </tbody>
                  </table>}
              </CardBody>
              <PaginationButtons
                className='d-print-none'
                data={items}
                label='items'
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card>
          </>
        ) : null}
      </Page>

      <OffCanvas
        setOpen={setAddAdvanceSalaryCanvas}
        isOpen={addAdvanceSalaryCanvas}
        titleId='addAdvanceSalaryCanvas'
        placement='end'
        isBodyScroll
        tag='form'
        noValidate
        onSubmit={salaryAdvanceForm.handleSubmit}>
        <OffCanvasHeader setOpen={setAddAdvanceSalaryCanvas} onClick={closeAndResetForm}>
          <OffCanvasTitle id='addAdvanceSalaryOffCanvas'>Add Advance Salary</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            {userTypeId == 1 ?
              <div className='col-12'>
                <FormGroup id='staffDetailsIdForAdd' label='Staff'>
                  <SearchableSelect
                    ariaLabel='Staff'
                    placeholder="Select Staff"
                    onChange={(e: any) => selectStaffForAdd(e)}
                    value={staffDetailsIdForAdd}
                    list={allStaffData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
                    isValid={salaryAdvanceForm.isValid}
                    isTouched={salaryAdvanceForm.touched.staffDetailsId}
                    invalidFeedback={salaryAdvanceForm.errors.staffDetailsId} />
                </FormGroup>
              </div> : null}
            <div className='col-12'>
              <FormGroup id='requestedDate' label='Date Of Apply'>
                <Input
                  aria-placeholder=''
                  onChange={salaryAdvanceForm.handleChange}
                  onBlur={salaryAdvanceForm.handleBlur}
                  value={salaryAdvanceForm.values.requestedDate}
                  isValid={salaryAdvanceForm.isValid}
                  isTouched={salaryAdvanceForm.touched.requestedDate}
                  invalidFeedback={salaryAdvanceForm.errors.requestedDate}
                  type='date' disabled
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='currentSalaryAmount' label='current Salary Amount'>
                <Input
                  onChange={salaryAdvanceForm.handleChange}
                  value={currentMonthSalary}
                  isValid={salaryAdvanceForm.isValid}
                  onBlur={salaryAdvanceForm.handleBlur}
                  isTouched={salaryAdvanceForm.touched.currentSalaryAmount}
                  invalidFeedback={salaryAdvanceForm.errors.currentSalaryAmount}
                  placeholder='current Salary Amount'
                  onKeyDown={(e: any) => onlyAllowNumber(e)}
                  disabled
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='requestedAmount' label='Requested Amount'>
                <Input
                  placeholder='Requested Amount'
                  onChange={salaryAdvanceForm.handleChange}
                  onBlur={salaryAdvanceForm.handleBlur}
                  value={salaryAdvanceForm.values.requestedAmount}
                  isValid={salaryAdvanceForm.isValid}
                  invalidFeedback={salaryAdvanceForm.errors.requestedAmount}
                  isTouched={salaryAdvanceForm.touched.requestedAmount}
                  onKeyDown={(e: any) => onlyAllowNumber(e)}
                />
              </FormGroup>
            </div>

            {/* <div className='col-12'>
              <FormGroup id='deductionMonth' label='Deduction Month'>
                <Input
                  placeholder='Deduction Month'
                  onChange={salaryAdvanceForm.handleChange}
                  onBlur={salaryAdvanceForm.handleBlur}
                  value={salaryAdvanceForm.values.deductionMonth}
                  isValid={salaryAdvanceForm.isValid}
                  invalidFeedback={salaryAdvanceForm.errors.deductionMonth}
                  isTouched={salaryAdvanceForm.touched.deductionMonth}
                  onKeyDown={(e: any) => onlyAllowNumber(e)}
                />
              </FormGroup>
            </div> */}

            <div className='col-12'>
              <FormGroup id='deductionDate' label='Deduction Start Date'>
                <Input
                  placeholder='Deduction Start Date'
                  onChange={salaryAdvanceForm.handleChange}
                  value={salaryAdvanceForm.values.deductionDate}
                  onBlur={salaryAdvanceForm.handleBlur}
                  isValid={salaryAdvanceForm.isValid}
                  isTouched={salaryAdvanceForm.touched.deductionDate}
                  invalidFeedback={salaryAdvanceForm.errors.deductionDate}
                  type='date'
                  min={
                    new Date()
                      .toISOString()
                      .split('T')[0]
                  }
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='approvalStaff' label='Pending With'>
                <SearchableSelect
                  ariaLabel='Staff'
                  placeholder="Select Staff"
                  onChange={(e: any) => setApprovalStaff(e)}
                  value={approvalStaff}
                  list={approvalStaffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
                  isValid={salaryAdvanceForm.isValid}
                  isTouched={salaryAdvanceForm.touched.approvalStaff}
                  invalidFeedback={salaryAdvanceForm.errors.approvalStaff} />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='reason' label='Reason'>
                <Textarea
                  autoComplete=''
                  onChange={salaryAdvanceForm.handleChange}
                  onBlur={salaryAdvanceForm.handleBlur}
                  value={salaryAdvanceForm.values.reason}
                  isValid={salaryAdvanceForm.isValid}
                  isTouched={salaryAdvanceForm.touched.reason}
                  invalidFeedback={salaryAdvanceForm.errors.reason}
                />
              </FormGroup>
            </div>
            <div className='col-12'><br /><br /><br /></div>
          </div>
        </OffCanvasBody>
        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
          <div className='row m-0'>
            <Button
              color='info'
              icon='Save'
              className='w-100'
              type='submit'
              isDisable={!salaryAdvanceForm.isValid && !!salaryAdvanceForm.submitCount}>
              Save
            </Button>
          </div>
        </div>
      </OffCanvas>

      <OffCanvas
        setOpen={setEditAdvanceSalaryCanvas}
        isOpen={editAdvanceSalaryCanvas}
        titleId='editAdvanceSalaryCanvas'
        placement='end'
        isBodyScroll
        tag='form'
        noValidate
        onSubmit={editSalaryAdvanceForm.handleSubmit}>
        <OffCanvasHeader setOpen={setEditAdvanceSalaryCanvas} onClick={closeAndResetForm}>
          <OffCanvasTitle id='editAdvanceSalaryOffCanvas'>Edit Advance Salary</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            {userTypeId == 1 ?
              <div className='col-12'>
                <FormGroup id='staffDetailsIdForAdd' label='Staff'>
                  <SearchableSelect
                    ariaLabel='Staff'
                    placeholder="Select Staff"
                    onChange={(e: any) => setStaffDetailsIdForAdd(e)}
                    value={staffDetailsIdForAdd}
                    list={allStaffData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
                    isValid={editSalaryAdvanceForm.isValid}
                    isTouched={editSalaryAdvanceForm.touched.staffDetailsId}
                    invalidFeedback={editSalaryAdvanceForm.errors.staffDetailsId} disabled />
                </FormGroup>
              </div> : null}
            <div className='col-12'>
              <FormGroup id='requestedDate' label='Date Of Apply'>
                <Input
                  aria-placeholder=''
                  onChange={editSalaryAdvanceForm.handleChange}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  value={editSalaryAdvanceForm.values.requestedDate}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.requestedDate}
                  invalidFeedback={editSalaryAdvanceForm.errors.requestedDate}
                  type='date' disabled
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='currentSalaryAmount' label='current Salary Amount'>
                <Input
                  onChange={editSalaryAdvanceForm.handleChange}
                  value={editSalaryAdvanceForm.values.currentSalaryAmount}
                  isValid={editSalaryAdvanceForm.isValid}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  isTouched={editSalaryAdvanceForm.touched.currentSalaryAmount}
                  invalidFeedback={editSalaryAdvanceForm.errors.currentSalaryAmount}
                  placeholder='current Salary Amount'
                  onKeyDown={(e: any) => onlyAllowNumber(e)}
                  disabled
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='requestedAmount' label='Requested Amount'>
                <Input
                  placeholder='Requested Amount'
                  onChange={editSalaryAdvanceForm.handleChange}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  value={editSalaryAdvanceForm.values.requestedAmount}
                  isValid={editSalaryAdvanceForm.isValid}
                  invalidFeedback={editSalaryAdvanceForm.errors.requestedAmount}
                  isTouched={editSalaryAdvanceForm.touched.requestedAmount}
                  onKeyDown={(e: any) => onlyAllowNumber(e)}
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='deductionMonth' label='Deduction Month'>
                <Input
                  placeholder='Deduction Month'
                  onChange={editSalaryAdvanceForm.handleChange}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  value={editSalaryAdvanceForm.values.deductionMonth}
                  isValid={editSalaryAdvanceForm.isValid}
                  invalidFeedback={editSalaryAdvanceForm.errors.deductionMonth}
                  isTouched={editSalaryAdvanceForm.touched.deductionMonth}
                  onKeyDown={(e: any) => onlyAllowNumber(e)} disabled
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='deductionDate' label='Deduction Start Date'>
                <Input
                  placeholder='Deduction Start Date'
                  onChange={editSalaryAdvanceForm.handleChange}
                  value={editSalaryAdvanceForm.values.deductionDate}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.deductionDate}
                  invalidFeedback={editSalaryAdvanceForm.errors.deductionDate}
                  type='date'
                  min={
                    new Date()
                      .toISOString()
                      .split('T')[0]
                  }
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='reason' label='Reason'>
                <Textarea
                  autoComplete=''
                  onChange={editSalaryAdvanceForm.handleChange}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  value={editSalaryAdvanceForm.values.reason}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.reason}
                  invalidFeedback={editSalaryAdvanceForm.errors.reason}
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='approvalStaff' label='Pending With'>
                <SearchableSelect
                  ariaLabel='Staff'
                  placeholder="Select Staff"
                  onChange={(e: any) => setApprovalStaff(e)}
                  value={approvalStaff}
                  list={approvalStaffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.approvalStaff}
                  invalidFeedback={editSalaryAdvanceForm.errors.approvalStaff} />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='statusId' label='Status'>
                <SearchableSelect
                  ariaLabel='Staff'
                  placeholder="Select Status"
                  onChange={(e: any) => setStatusId(e)}
                  value={statusId}
                  list={statusData.map((data: any) => ({ value: data.statusId, label: data.statusName }))}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.statusId}
                  invalidFeedback={editSalaryAdvanceForm.errors.statusId} />
              </FormGroup>
            </div>


            {statusId?.value != 1 ? <div className='col-12'>
              <FormGroup id='remarks' label='Remarks'>
                <Textarea
                  autoComplete=''
                  onChange={editSalaryAdvanceForm.handleChange}
                  onBlur={editSalaryAdvanceForm.handleBlur}
                  value={editSalaryAdvanceForm.values.remarks}
                  isValid={editSalaryAdvanceForm.isValid}
                  isTouched={editSalaryAdvanceForm.touched.remarks}
                  invalidFeedback={editSalaryAdvanceForm.errors.remarks}
                />
              </FormGroup>
            </div> : null}

            <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
              <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteSalaryAdvanceSubmit} />
            </div>

            <div className='col-12'><br /><br /><br /><br /><br /></div>

          </div>
        </OffCanvasBody>

        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
          <div className='row m-0'>
            <div className='col-6 p-3'>
              <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                Delete
              </Button>
            </div>
            <div className='col-6 p-3'>
              <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!editSalaryAdvanceForm.isValid && !!editSalaryAdvanceForm.submitCount}> Update</Button>
            </div>
          </div>
        </div>
      </OffCanvas>

      <Modal
        setIsOpen={setStatusModal}
        isOpen={statusModal}
        titleId='statusModal'
        isStaticBackdrop
        size='lg'
      >
        <ModalHeader className="'modal-header'"
          setIsOpen={() => { setStatusModal(false) }} onClick={closeAndResetForm}>
          <ModalTitle id="statusModal"> Status</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className='col-12'>
              <table className='table table-modern table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <td style={{ width: '30px' }} className='text-decoration-underline'>
                      <strong>Action By</strong>
                    </td>
                    <td style={{ width: '25px' }} className='text-decoration-underline'>
                      <strong>Staff Name</strong>
                    </td>
                    <td style={{ width: '25px' }} className='text-decoration-underline'>
                      <strong>Action</strong>
                    </td>
                    <td style={{ width: '25px' }} className='text-decoration-underline'>
                      <strong>Pending With</strong>
                    </td>
                    <td style={{ width: '10px' }} className='text-decoration-underline'>
                      <strong>Status</strong>
                    </td>
                    <td style={{ width: '10px' }} className='text-decoration-underline'>
                      <strong>Date & Time</strong>
                    </td>
                    <td style={{ width: '25px' }} className='text-decoration-underline'>
                      <strong>Remarks</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {staffRequestHistoryData.map(
                    (status: any) => (
                      <tr key={status.staffRequestHistoryId}>
                        <td>{status.actionStaffName}</td>
                        <td>{status.staffName}</td>
                        <td>{status.action}</td>
                        <td>{status.assignedStaffName != '' ? status.assignedStaffName : "-"}</td>
                        <td className={status.statusId == 1 ? 'text-info' : status.statusId == 2 ? 'text-success' : 'text-danger'}> <Icon icon='Circle' color={status.statusId == 1 ? 'info' : status.statusId == 2 ? 'success' : 'danger'} />{" "}{status.statusName}</td>
                        <td>{dayjs(status.statusUpdatedOn).format('D MMMM, YYYY h:mm A')}</td>
                        <td>{status.remarks}</td>
                      </tr>))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        setIsOpen={setDisposalModal}
        isOpen={disposalModal}
        titleId='disposalModal'
        isStaticBackdrop
        size='lg'>
        <ModalHeader className="'modal-header'"
          setIsOpen={() => { setDisposalModal(false) }} onClick={closeAndResetForm}>
          <ModalTitle id="disposalModal">Disposal</ModalTitle>
        </ModalHeader>
        <ModalBody >
          <form noValidate onSubmit={payFeesDenominationForm.handleSubmit}>
            <div className='row'>
              <h5 className="main-content-label mb-1 text-danger text-center fw-bold">Salary Advance Requested amount - ₹ {advanceSalaryTotalAmount}</h5>
            </div>

            <CardBody>
              <div className='row h-100 pb-3'>
                <div className='col-lg-4 col-md-6'>
                  <Card stretch>
                    <CardHeader>
                      <CardLabel icon='List'>
                        <CardTitle tag='div' className='h5'>
                          Payment Denomination
                        </CardTitle>
                      </CardLabel>
                    </CardHeader>
                    <CardBody>
                      <div className='row g-3'>
                        <div className='col-12'>
                          <Button
                            icon='PendingActions'
                            color='info'
                            className='w-100 p-3'
                            isLight={TABS.CASH_PAYMENTS !== activeTab}
                            onClick={() => { setActiveTab(TABS.CASH_PAYMENTS); formReset(); setPaymentTypeId(1); }}>
                            {TABS.CASH_PAYMENTS}
                          </Button>
                        </div>
                        <div className='col-12'>
                          <Button
                            icon='LocalPolice'
                            color='info'
                            className='w-100 p-3'
                            isLight={TABS.CHEQUE !== activeTab}
                            onClick={() => { setActiveTab(TABS.CHEQUE); formReset(); setPaymentTypeId(2); }}>
                            {TABS.CHEQUE}
                          </Button>
                        </div>
                        <div className='col-12'>
                          <Button
                            icon='Style'
                            color='info'
                            className='w-100 p-3'
                            isLight={TABS.NEFT_UPI !== activeTab}
                            onClick={() => { setActiveTab(TABS.NEFT_UPI); formReset(); setPaymentTypeId(3); }}>
                            {TABS.NEFT_UPI}
                          </Button>
                        </div>
                        <div className='col-12'>
                          <Button
                            icon='CreditCard'
                            color='info'
                            className='w-100 p-3'
                            isLight={TABS.CARD_PAYMENT !== activeTab}
                            onClick={() => { setActiveTab(TABS.CARD_PAYMENT); formReset(); setPaymentTypeId(4); }}>
                            {TABS.CARD_PAYMENT}
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className='col-lg-8 col-md-6'>
                  {TABS.CASH_PAYMENTS === activeTab && (
                    <Card stretch>
                      <CardHeader>
                        <CardLabel icon='PendingActions'>
                          <CardTitle tag='div' className='h5'>
                            Cash Denomination
                          </CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <CardBody>
                        <div className='row'>
                          <div className="d-grid gap-4">
                            <div className="row justify-content-center">
                              <div className="col-lg-4 align-self-center h6">
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                Count
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                Amount
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>500 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="fiveHundred_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fiveHundred_count} onInput={multiply500} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="fiveHundred_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fiveHundred_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>200 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="twoHundred_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twoHundred_total} onInput={multiply200} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="twoHundred_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twoHundred_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>100 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="hundred_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.hundred_count} onInput={multiply100} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="hundred_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={hundred_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>50 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="fifty_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fifty_count} onInput={multiply50} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="fifty_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fifty_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>20 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="twenty_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twenty_count} onInput={multiply20} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="twenty_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twenty_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>10 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="ten_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.ten_count} onInput={multiply10} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="ten_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={ten_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p>5 X</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="five_count" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.five_count} onInput={multiply5} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                              </div>

                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="five_total" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={five_total} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p></p>
                              </div>
                              <div className="col-lg-4 mt-2 justify-content-center">
                                <p>Total Amount</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="totalAmount" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={totalAmount} disabled />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-1 align-self-center h6">
                              </div>
                              <div className="col-lg-3 mt-2 align-self-center h6">
                                <p></p>
                              </div>
                              <div className="col-lg-4 mt-2 justify-content-center">
                                <p>Balance Cash Amount</p>
                              </div>
                              <div className="col-lg-4 justify-content-center">
                                <FormGroup id="balCashAmount" label="">
                                  <Input className={balCashAmount < 0 ? 'border border-danger' : ''} type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={balCashAmount} readOnly />
                                  <>
                                    {balCashAmount < 0 ?
                                      <div className='ms-2 text-danger'>
                                        Amount Not Matching !{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                      </div> : null}
                                  </>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {TABS.CHEQUE === activeTab && (
                    <Card stretch>
                      <CardHeader>
                        <CardLabel icon='LocalPolice'>
                          <CardTitle tag='div' className='h5'>
                            Cheque Details
                          </CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <CardBody>
                        <div className='row'>
                          <div className="d-grid gap-4">
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Cheque Number</p>
                              </div>
                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="chequeNumber" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeNumber} />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Amount</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="chequeAmount" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} onBlur={payFeesDenominationForm.handleBlur} value={payFeesDenominationForm.values.chequeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />

                                </FormGroup>
                                <>
                                  {advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.chequeAmount) ? null : (
                                    <div className="ms-2 mt-2 text-danger">
                                      Amount Not Matching !{' '}
                                      <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                    </div>
                                  )}
                                </>
                              </div>


                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Cheque Date</p>
                              </div>
                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="chequeDate" label="">
                                  <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeDate} />
                                </FormGroup>
                              </div>

                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Bank Name</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="chequeBankName" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeBankName} />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {TABS.NEFT_UPI === activeTab && (
                    <Card stretch>
                      <CardHeader>
                        <CardLabel icon='Style' iconColor='primary'>
                          <CardTitle tag='div' className='h5'>
                            NEFT / UPI Details
                          </CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <CardBody>
                        <div className='row'>
                          <div className="d-grid gap-4">
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>NEFT / UPI Ref Number</p>
                              </div>
                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="neftRefNumber" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftRefNumber} />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Amount</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="neftAmount" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                                <>
                                  {advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.neftAmount) ? null : (
                                    <div className="ms-2 mt-2 text-danger">
                                      Amount Not Matching !{' '}
                                      <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Transaction Date</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="neftTranDate" label="">
                                  <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftTranDate} />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Bank Name</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="neftBankName" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftBankName} />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {TABS.CARD_PAYMENT === activeTab && (
                    <Card stretch>
                      <CardHeader>
                        <CardLabel icon='CreditCard' iconColor='primary'>
                          <CardTitle tag='div' className='h5'>
                            Card Details
                          </CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <CardBody>
                        <div className='row'>
                          <div className="d-grid gap-4">
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Card Number</p>
                              </div>
                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="cardNumber" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardNumber} />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Amount</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="cardAmount" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                                <>
                                  {advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.cardAmount) ? null : (
                                    <div className="ms-2 mt-2 text-danger">
                                      Amount Not Matching !{' '}
                                      <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Transaction Date</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="cardDate" label="">
                                  <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardDate} />
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-lg-4 mt-2 align-self-center h6">
                                <p>Bank Name</p>
                              </div>

                              <div className="col-lg-7 justify-content-center">
                                <FormGroup id="cardBankName" label="">
                                  <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardBankName} />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </div>
              <div className='row h-100 pb-3'>
                <div className="col-lg-9">
                  <FormGroup id="narration" label="Remarks" isFloating>
                    <Textarea placeholder='Enter Remarks' onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.narration} />
                  </FormGroup>
                </div>

                {paymentTypeId == 1 || paymentTypeId == "" ?
                  <div className="col-lg-3 mt-2">
                    <Button color='info' icon='Save' type="submit" isDisable={totalAmount > 0 ? false : true} >Save</Button>
                  </div>
                  : null}
                {paymentTypeId == 2 ?
                  <div className="col-lg-3 mt-2">
                    <Button color='info' icon='Save' type="submit" isDisable={Number(advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.chequeAmount)) ? false : true}>Save</Button>
                  </div>
                  : null}

                {paymentTypeId == 3 ?
                  <div className="col-lg-3 mt-2">
                    <Button color='info' icon='Save' type="submit" isDisable={Number(advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.neftAmount)) ? false : true} >Save</Button>
                  </div>
                  : null}

                {paymentTypeId == 4 ?
                  <div className="col-lg-3 mt-2">
                    <Button color='info' icon='Save' type="submit" isDisable={Number(advanceSalaryTotalAmount == Number(payFeesDenominationForm.values.cardAmount)) ? false : true}>Save</Button>
                  </div>
                  : null}
              </div>
            </CardBody>
          </form>
        </ModalBody>
      </Modal>

      <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
    </PageWrapper>
  );
}

export default SalaryAdvance;
