import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

// SALARY COMPONENTS
export const getEarningDetailsList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEarningDetailsList/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getEarningDetailsList - " + error.message)
            console.log('error caught in service : getEarningDetailsList')
        },
    );

export const addEarningDetails = (earningPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addEarningDetails`, earningPostData,
        response => {
            success(response)
        },
        error => {
            failure("addEarningDetails - " + error.message)
            console.log('error caught in service : addEarningDetails')
        },
    );


export const updateEarningDetails = (earningPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateEarningDetails`, earningPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateEarningDetails - " + error.message)
            console.log('error caught in service : updateEarningDetails')
        },
    );

export const deleteEarningDetails = (earningPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteEarningDetails`, earningPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteEarningDetails - " + error.message)
            console.log('error caught in service : deleteEarningDetails')
        },
    );

export const addAdvanceSalary = (salaryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addAdvanceSalary`, salaryPostData,
        response => {
            success(response)
        },
        error => {
            failure("addAdvanceSalary - " + error.message)
            console.log('error caught in service : addAdvanceSalary')
        },
    );

export const getAdvanceSalaryList = (advanceSalaryDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAdvanceSalary/${getLicenseKey}/${advanceSalaryDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAdvanceSalary - " + error.message)
            console.log('error caught in service : getAdvanceSalary')
        },
    );

export const getEarningTypeList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEarningTypeList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEarningTypeList - " + error.message)
            console.log('error caught in service : getEarningTypeList')
        },
    );

export const updateAdvanceSalaryStatus = (advanceSalaryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateAdvanceSalary`, advanceSalaryPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateAdvanceSalary - " + error.message)
            console.log('error caught in service : updateAdvanceSalary')
        },
    );

// SALARY TEMPLATE
export const getSalaryTemplateList = (organisationDetailsId: any, salaryTemplateId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSalaryTemplateList/${getLicenseKey}/${organisationDetailsId}/${salaryTemplateId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSalaryTemplateList - " + error.message)
            console.log('error caught in service : getSalaryTemplateList')
        },
    );

export const addSalaryTemplate = (salaryTemplatePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSalaryTemplate`, salaryTemplatePostData,
        response => {
            success(response)
        },
        error => {
            failure("addSalaryTemplate - " + error.message)
            console.log('error caught in service : addSalaryTemplate')
        },
    );

export const deleteSalaryTemplate = (salaryTemplatePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSalaryTemplate`, salaryTemplatePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteSalaryTemplate - " + error.message)
            console.log('error caught in service : deleteSalaryTemplate')
        },
    );

export const getWorkingDayList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getWorkingDayList/${getLicenseKey}/${2024}/06`,
        response => {
            success(response)
        },
        error => {
            failure("getWorkingDayList - " + error.message)
            console.log('error caught in service : getWorkingDayList')
        },
    );

export const getStaffExtraSalaryDetails = (staffDetailsId: any, salaryTypeId: any, workingDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffExtraSalaryDetails/${getLicenseKey}/${staffDetailsId}/${salaryTypeId}/${workingDate}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffExtraSalaryDetails - " + error.message)
            console.log('error caught in service : getStaffExtraSalaryDetails')
        },
    );
export const getStaffListByDepartmentId = (departmentId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByDepartmentId/${getLicenseKey}/${departmentId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByDepartmentId - " + error.message)
            console.log('error caught in service : getStaffListByDepartmentId')
        },
    );

export const getSalaryType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSalaryType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSalaryType - " + error.message)
            console.log('error caught in service : getSalaryType')
        },
    );
export const addStaffExtraSalaryDetails = (staffExtraDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffExtraSalaryDetails`, staffExtraDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffExtraSalaryDetails')
        }
    );
export const updateStaffExtraSalaryDetails = (staffExtraDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffExtraSalaryDetails`, staffExtraDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffExtraSalaryDetails - " + error.message)
            console.log('error caught in service : updateStaffExtraSalaryDetails')
        },
    );

export const deleteStaffExtraSalaryDetails = (staffExtraDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffExtraSalaryDetails`, staffExtraDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteStaffExtraSalaryDetails - " + error.message)
            console.log('error caught in service : deleteStaffExtraSalaryDetails')
        },
    );


export const getStaffListForPayRun = (year: any, month: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListForPayRun/${getLicenseKey}/${year}/${month}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListForPayRun - " + error.message)
            console.log('error caught in service : getStaffListForPayRun')
        },
    );


export const getSalaryAdvanceList = (userTypeId: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSalaryAdvanceList/${getLicenseKey}/${userTypeId}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSalaryAdvanceList - " + error.message)
            console.log('error caught in service : getSalaryAdvanceList')
        },
    );

export const addSalaryAdvance = (salaryAdvancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSalaryAdvance`, salaryAdvancePostData,
        response => {
            success(response)
        },
        error => {
            failure("addSalaryAdvance - " + error.message)
            console.log('error caught in service : addSalaryAdvance')
        },
    );

export const updateSalaryAdvance = (salaryAdvancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSalaryAdvance`, salaryAdvancePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateSalaryAdvance - " + error.message)
            console.log('error caught in service : updateSalaryAdvance')
        },
    );

export const deleteSalaryAdvance = (salaryAdvancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSalaryAdvance`, salaryAdvancePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteSalaryAdvance - " + error.message)
            console.log('error caught in service : deleteSalaryAdvance')
        },
    );


export const getSalaryAdvanceScheduleDetails = (salaryAdvanceId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSalaryAdvanceList/${getLicenseKey}/0/0/${salaryAdvanceId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSalaryAdvanceList - " + error.message)
            console.log('error caught in service : getSalaryAdvanceList')
        },
    );

export const getStaffRevisedSalaryDetails = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffRevisedSalaryDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffRevisedSalaryDetails - " + error.message)
            console.log('error caught in service : getStaffRevisedSalaryDetails')
        },
    );

export const updateRevisedSalaryDetails = (revisedSalaryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateRevisedSalaryDetails`, revisedSalaryPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateRevisedSalaryDetails - " + error.message)
            console.log('error caught in service : updateRevisedSalaryDetails')
        },
    );

export const addUpdateStaffSalaryPaidDetails = (salaryDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addUpdateStaffSalaryPaidDetails`, salaryDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure("addUpdateStaffSalaryPaidDetails - " + error.message)
            console.log('error caught in service : addUpdateStaffSalaryPaidDetails')
        },
    );

export const getStaffSalaryReviseForBulkUpload = (year: any, month: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffSalaryReviseForBulkUpload/${getLicenseKey}/${year}/${month}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffSalaryReviseForBulkUpload - " + error.message)
            console.log('error caught in service : getStaffSalaryReviseForBulkUpload')
        },
    );

    export const addStaffSalaryReviseForBulkUpload = (revisedSalaryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffSalaryReviseForBulkUpload`, revisedSalaryPostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffSalaryReviseForBulkUpload - " + error.message)
            console.log('error caught in service : addStaffSalaryReviseForBulkUpload')
        },
    );

    export const excelUpload = (salaryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `excelUpload`, salaryPostData,
        response => {
            success(response)
        },
        error => {
            failure("excelUpload - " + error.message)
            console.log('error caught in service : excelUpload')
        },
    );