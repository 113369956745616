import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toasts } from '../services/toast.service';
import { getEmployeeProfile } from '../services/employee.service';
import { getProfileForStaff } from '../services/staff.service';

export interface IAuthContextProps {
	userAccountId: any;
	userTypeId: any;
	organisationDetailsId: any;
	userData: any;
	setUserAccountId?(...args: unknown[]): unknown;
	setUserData?(...args: unknown[]): unknown;
	setUserTypeId?(...args: unknown[]): unknown;
	setOrganisationDetailsId?(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [userAccountId, setUserAccountId] = useState<string>(localStorage.getItem('userAccountId') || '');
	const [userTypeId, setUserTypeId] = useState<string>(localStorage.getItem('userTypeId') || '');
	const [organisationDetailsId, setOrganisationDetailsId] = useState<string>(localStorage.getItem('organisationDetailsId') || '');
	const [userData, setUserData] = useState<any>({});

	useEffect(() => {
		localStorage.setItem('userAccountId', userAccountId);
	}, [userAccountId]);

	useEffect(() => {
		if (userAccountId !== '') {
			getStaffProfile(userAccountId);
		}
	}, [userAccountId]);

	function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
					let data = response.data.data.profileForStaff[0];
					if (data != undefined) {
						setUserData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

	const value = useMemo(
		() => ({
			userAccountId,
			setUserAccountId,
			userTypeId,
			setUserTypeId,
			organisationDetailsId,
			setOrganisationDetailsId,
			userData,

		}),
		[userAccountId, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;