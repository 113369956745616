import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

// ORGANISATION 
export const getOrganisationDetails = (organisationDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getOrganisationDetails/${getLicenseKey}/${organisationDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getOrganisationDetails - " + error.message)
            console.log('error caught in service : getOrganisationDetails')
        },
    );

export const getOrganisationDetailsByOrganisationDetailsId = (organisationDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getOrganisationDetails/${getLicenseKey}/${organisationDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getOrganisationDetails - " + error.message)
            console.log('error caught in service : getOrganisationDetails')
        },
    );

export const updateOrganisationDetails = (organisationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateOrganisationDetails`, organisationPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateOrganisationDetails - " + error.message)
            console.log('error caught in service : updateOrganisationDetails')
        },
    );

export const addOrganisationDetails = (organisationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addOrganisationDetails`, organisationPostData,
        response => {
            success(response)
        },
        error => {
            failure("addOrganisationDetails - " + error.message)
            console.log('error caught in service : addOrganisationDetails')
        },
    );

export const getIndustry = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getIndustry/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getIndustry - " + error.message)
            console.log('error caught in service : getIndustry')
        },
    );

export const getDateFormats = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDateFormats/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDateFormats - " + error.message)
            console.log('error caught in service : getDateFormats')
        },
    );

// WorkLocation
export const getWorkLocationsList = (organisationDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getWorkLocationsList/${getLicenseKey}/${organisationDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getWorkLocationsList - " + error.message)
            console.log('error caught in service : getWorkLocationsList')
        },
    );

export const getWorkLocationsListByWorkLocationId = (organisationDetailsId: any, workLocationId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getWorkLocationsList/${getLicenseKey}/${organisationDetailsId}/${workLocationId}`,
        response => {
            success(response)
        },
        error => {
            failure("getWorkLocationsList - " + error.message)
            console.log('error caught in service : getWorkLocationsList')
        },
    );

export const addWorkLocation = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addWorkLocation`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure("addWorkLocation - " + error.message)
            console.log('error caught in service : addWorkLocation')
        },
    );

export const updateWorkLocation = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateWorkLocation`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateWorkLocation - " + error.message)
            console.log('error caught in service : updateWorkLocation')
        },
    );

export const deleteWorkLocation = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteWorkLocation`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteWorkLocation - " + error.message)
            console.log('error caught in service : deleteWorkLocation')
        },
    );

//DEPARTMENT 
export const getDepartmentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDepartmentList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDepartmentList - " + error.message)
            console.log('error caught in service : getDepartmentList')
        },
    );

export const addDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure("addDepartment - " + error.message)
            console.log('error caught in service : addDepartment')
        },
    );

export const updateDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateDepartment - " + error.message)
            console.log('error caught in service : updateDepartment')
        },
    );

export const deleteDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteDepartment - " + error.message)
            console.log('error caught in service : deleteDepartment')
        },
    );

//DESIGNATION 
export const getDesignationList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDesignationList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDesignationList - " + error.message)
            console.log('error caught in service : getDesignationList')
        },
    );

export const getStaffDesignation = (userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDesignation/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDesignation - " + error.message)
            console.log('error caught in service : getStaffDesignation')
        },
    );

export const addStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffDesignation - " + error.message)
            console.log('error caught in service : addStaffDesignation')
        },
    );

export const updateStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffDesignation - " + error.message)
            console.log('error caught in service : updateStaffDesignation')
        },
    );

export const deleteStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteStaffDesignation - " + error.message)
            console.log('error caught in service : deleteStaffDesignation')
        },
    );

//PAY SCHEDULE
export const getPayScheduleDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPayScheduleDetails/${getLicenseKey}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getPayScheduleDetails - " + error.message)
            console.log('error caught in service : getPayScheduleDetails')
        },
    );

export const getUpcomingPayrolls = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUpcomingPayrolls/${getLicenseKey}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getUpcomingPayrolls - " + error.message)
            console.log('error caught in service : getUpcomingPayrolls')
        },
    );

export const updatePaySchedule = (updatePaySchedulePostDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updatePaySchedule`, updatePaySchedulePostDate,
        response => {
            success(response)
        },
        error => {
            failure("updatePaySchedule - " + error.message)
            console.log('error caught in service : updatePaySchedule')
        },
    );

export const updateUser = (employeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateUser`, employeePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateUser - " + error.message)
            console.log('error caught in service : updateUser')
        },
    );

// userRole
export const getUserRoleList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUserRoleList/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getUserRoleList - " + error.message)
            console.log('error caught in service : getUserRoleList')
        },
    );